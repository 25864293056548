import api from "./customApiInstance";

export interface UserDetails {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  picture_url: string;
  status: string;
  [key: string]: any; // For any additional properties
}

interface ApiResponse {
  data: UserDetails;
}

// Function to update user details in localStorage
const updateUserDetailsInStorage = (
  userDetails: Partial<UserDetails>
): UserDetails => {
  const storedUserDetails = localStorage.getItem("userDetails");
  let updatedDetails: UserDetails;

  if (storedUserDetails) {
    const currentDetails: UserDetails = JSON.parse(storedUserDetails);
    updatedDetails = { ...currentDetails, ...userDetails };
  } else {
    updatedDetails = userDetails as UserDetails;
  }

  localStorage.setItem("userDetails", JSON.stringify(updatedDetails));
  return updatedDetails;
};

// Function to fetch logged-in user info
const getLoggedInUserInfo = async (
  forceServerFetch: boolean = false
): Promise<UserDetails | null> => {
  if (!forceServerFetch) {
    const storedUserDetails = localStorage.getItem("userDetails");

    if (storedUserDetails) {
      const userDetails: UserDetails = JSON.parse(storedUserDetails);
      // Check if stored data is still valid (you might want to add more checks)
      if (userDetails.id && userDetails.email && userDetails.status) {
        return userDetails;
      }
    }
  }

  try {
    const response = await api.get<ApiResponse>("/users/logged-in-user-info/", {
      authRequired: true,
    });
    const userDetails = response.data;
    updateUserDetailsInStorage(userDetails);
    return userDetails;
  } catch (error) {
    console.error("Error in getLoggedInUserInfo:", error);
    return null;
  }
};

// Function to update logged-in user details
const updateLoggedInUser = (details: Partial<UserDetails>): UserDetails => {
  return updateUserDetailsInStorage(details);
};

// Function to get navigation page based on user status
const getLoggedInUserNavigationPage = async (
  status?: string
): Promise<string> => {
  if (!status) {
    const user = await getLoggedInUserInfo();
    status = user?.status;
  }

  // Here you can implement logic to return different pages based on status
  // For now, it always returns '/home'
  return "/apps";
};

const capitalizeFirstLetters = (sentence: string): string => {
  if (sentence) {
    return sentence.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  return sentence;
};

export {
  getLoggedInUserInfo,
  updateLoggedInUser,
  getLoggedInUserNavigationPage,
  capitalizeFirstLetters,
};
