import React from "react";
import { FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

const AboutUsPage: React.FC = () => {
  return (
    <div className="max-w-5xl mx-auto p-6">
      <section className="text-center">
        <h2 className="text-3xl font-semibold mb-5 text-gray-800">About Us</h2>
        <div className="text-gray-600 mb-10">LORESPAM</div>
      </section>

      <div className="grid grid-cols-1 sm:grid-cols-1 gap-8">
        <section className="bg-white rounded-lg p-6 shadow-lg">
          <div className="text-center">
            <img
              src="mariane-profile.jpeg"
              alt="Amanda Cheng"
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <h2 className="text-2xl font-semibold text-gray-800">
              Amanda Cheng
            </h2>
            <h2 className="text-lg text-gray-800">Founder & CEO</h2>

            <div className="flex justify-center mt-4">
              <a
                href="https://www.linkedin.com/in/marianebekker/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="w-6 h-6 mr-1 text-blue-500" />
              </a>
              <a
                href="https://twitter.com/marianebekker"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="w-6 h-6 mr-1 text-blue-400" />
              </a>
              <a
                href="https://www.instagram.com/marianebekker/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="w-6 h-6 text-pink-500" />
              </a>
            </div>
          </div>

          <p className="text-gray-600 mt-4">Loresepam</p>
        </section>
      </div>
    </div>
  );
};

export default AboutUsPage;
