import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getLoggedInUserInfo,
  updateLoggedInUser,
  UserDetails,
} from "../services/authUtils";
import api from "../services/customApiInstance";

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  userDetails: UserDetails | null;
  fetchUserData: () => void;
  updateAuthStatus: (details: Partial<UserDetails>, token: string) => void;
  logout: () => void;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  loading: true,
  userDetails: null,
  updateAuthStatus: () => {},
  fetchUserData: () => {},
  logout: () => {},
};

export const AuthContext = createContext<AuthState>(initialAuthState);

const publicPathsWithoutVerify = ["/login", "/register", "/forgot-password"];

const publicPaths = [...publicPathsWithoutVerify, "/verify-email"];

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!localStorage.getItem("token")
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUserData = async () => {
    const storedAuthStatus = !!localStorage.getItem("token");
    setIsAuthenticated(storedAuthStatus);

    if (storedAuthStatus) {
      try {
        const userData = await getLoggedInUserInfo(true);
        if (userData) {
          setUserDetails(userData);
          if (publicPathsWithoutVerify.includes(location.pathname)) {
            navigate("/apps");
          }
        } else {
          logout();
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        logout();
      }
    } else if (!publicPaths.includes(location.pathname)) {
      navigate("/login");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, [navigate, location.pathname]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "token") {
        console.log("Token changed in another tab", e.newValue);
        setIsAuthenticated(!!e.newValue);
        if (!e.newValue && !publicPaths.includes(location.pathname)) {
          setUserDetails(null);
          navigate("/login");
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate, location.pathname]);

  const updateAuthStatus = (details: Partial<UserDetails>, token: string) => {
    if (details) {
      const updatedDetails = updateLoggedInUser(details);
      setUserDetails(updatedDetails);
    }

    if (token) {
      localStorage.setItem("token", token);
    }

    setIsAuthenticated(!!token);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    api.post("/users/logout/");
    setIsAuthenticated(false);
    setUserDetails(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        updateAuthStatus,
        logout,
        loading,
        userDetails,
        fetchUserData,
      }}
    >
      {!loading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  );
};
