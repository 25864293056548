import * as React from "react";
import { cn } from "@/lib/utils";

export interface InputWithInsetLabelProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
}

const InputWithInsetLabel = React.forwardRef<
  HTMLInputElement,
  InputWithInsetLabelProps
>(
  (
    {
      className,
      type,
      label,
      containerClassName,
      labelClassName,
      inputClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          "rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-cyan-600 bg-white",
          containerClassName
        )}
      >
        {label && (
          <label
            htmlFor={props.id || props.name}
            className={cn(
              "block text-xs font-medium text-gray-900",
              labelClassName
            )}
          >
            {label}
          </label>
        )}
        <input
          type={type}
          className={cn(
            "block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
            "bg-transparent",
            inputClassName,
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
InputWithInsetLabel.displayName = "Input";

export { InputWithInsetLabel };
