import customApi from "./customApiInstance";

export interface EmailExample {
  sender: string;
  subject: string;
  body: string;
  threadId?: string;
  labels?: string[];
}

export interface EmailType {
  id?: number;
  organization: number;
  title: string;
  color: string;
  instructions?: string;
  examples: EmailExample[];
}

export interface CreateEmailTypeData {
  organization: number;
  title: string;
  color: string;
  examples: EmailExample[];
}

export interface OAuthCredential {
  id: number;
  provider: string;
  expires_in: number;
}

export interface EmailAccount {
  id: number;
  email: string;
  is_primary: boolean;
  oauth_credential_provider: string;
}

export interface CreateEmailAccountData {
  code: string;
}

export interface EmailResponseData {
  email_content: string;
  sender: string;
  receiver: string;
  subject: string;
}

export interface RecentEmail {
  sender: string;
  subject: string;
  body: string;
}

export interface BackgroundJob {
  id: string;
  process_name: string;
  status: "PENDING" | "SUCCEEDED" | "ERROR";
  created: string;
  modified: string;
}

export type RawEmailData = [string, string][];

export const emailAccountApi = {
  getAll: () =>
    customApi
      .get<EmailAccount[]>("/email/accounts/")
      .then((response) => response.data),
  getOne: (id: number) =>
    customApi
      .get<EmailAccount>(`/email/accounts/${id}/`)
      .then((response) => response.data),
  create: (data: CreateEmailAccountData) =>
    customApi
      .post<EmailAccount>("/email/accounts/add_email_account/", data)
      .then((response) => response.data),
  delete: (id: number) =>
    customApi
      .delete(`/email/accounts/${id}/remove_email_account/`)
      .then((response) => response.data),
};

export const emailTypeApi = {
  getAll: () =>
    customApi
      .get<EmailType[]>("/email/types/")
      .then((response) => response.data),
  getOne: (id: number) =>
    customApi
      .get<EmailType>(`/email/types/${id}/`)
      .then((response) => response.data),
  create: (data: CreateEmailTypeData) =>
    customApi
      .post<EmailType>("/email/types/", data)
      .then((response) => response.data),
  update: (id: number, data: Partial<CreateEmailTypeData>) =>
    customApi
      .put<EmailType>(`/email/types/${id}/`, data)
      .then((response) => response.data),
  delete: (id: number) =>
    customApi.delete(`/email/types/${id}/`).then((response) => response.data),
};

export const gmailAccountApi = {
  addAccount: () =>
    customApi
      .get<{ auth_url: string }>("/email/oauth/gmail/add/")
      .then((response) => response.data),
};

export const emailResponseApi = {
  generateResponse: (data: EmailResponseData) =>
    customApi
      .post<{ response: string }>("/email/generate-response/", data)
      .then((response) => response.data),
};

export const recentEmailsApi = {
  getRecentEmails: (withCategoriesApplied: boolean = false) =>
    customApi
      .get<RecentEmail[] | { message: string }>("/email/accounts/head/", {
        params: { withCategoriesApplied },
      })
      .then((response) => response.data),
  getPreview: () =>
    customApi
      .get<RawEmailData[] | { message: string }>(
        "/email/accounts/head/preview/"
      )
      .then((response) => response.data),
  applyPreview: () =>
    customApi
      .get<{ message: string }>("/email/accounts/head/preview/apply/")
      .then((response) => response.data),
};

export const backgroundJobApi = {
  getAll: () =>
    customApi.get<BackgroundJob[]>("/jobs/").then((response) => response.data),

  getPending: () =>
    customApi
      .get<BackgroundJob[]>("/jobs/pending/")
      .then((response) => response.data),

  getOne: (id: string) =>
    customApi
      .get<BackgroundJob>(`/jobs/${id}/`)
      .then((response) => response.data),
};

export interface EmailsOrganizedStats {
  emails_organized: number;
}

export interface PatientsConnectedStats {
  patients_connected: number;
}

export interface TimeSavedStats {
  time_saved_hours: number;
}

// New api object for dashboard statistics
export const dashboardStatsApi = {
  getEmailsOrganized: () =>
    customApi
      .get<EmailsOrganizedStats>("/email/statistics/emails-organized/")
      .then((response) => response.data),

  getPatientsConnected: () =>
    customApi
      .get<PatientsConnectedStats>("/email/statistics/patients-connected/")
      .then((response) => response.data),

  getTimeSaved: () =>
    customApi
      .get<TimeSavedStats>("/email/statistics/time-saved/")
      .then((response) => response.data),

  getAllStats: async () => {
    try {
      const [emailsOrganized, patientsConnected, timeSaved] = await Promise.all(
        [
          customApi.get<EmailsOrganizedStats>(
            "/email/statistics/emails-organized/"
          ),
          customApi.get<PatientsConnectedStats>(
            "/email/statistics/patients-connected/"
          ),
          customApi.get<TimeSavedStats>("/email/statistics/time-saved/"),
        ]
      );

      return {
        emailsOrganized: emailsOrganized.data.emails_organized,
        patientsConnected: patientsConnected.data.patients_connected,
        timeSaved: timeSaved.data.time_saved_hours,
      };
    } catch (error) {
      console.error("Error fetching dashboard stats:", error);
      throw error;
    }
  },
};

// Types
export interface AudioFile {
  id: string;
  file: string;
}

export interface Transcript {
  id: string;
  text: string;
  language: string;
}

export interface Organization {
  id: string;
  name: string;
}

export interface Summary {
  id: string;
  text: string;
  type: "PATIENT" | "REFERRING_DENTIST" | "SOAP" | "EHR";
}

export interface Recording {
  id: string;
  title: string;
  description: string;
  patient_first_name: string;
  patient_last_name: string;
  audio_file: AudioFile;
  transcript: Transcript;
  organization: Organization;
  summaries: Summary[];
}

export interface RecordingCreate {
  patient_first_name: string;
  patient_last_name: string;
}

export interface RecordingPin {
  id: string;
  pin: string;
  user: string;
}

export interface RecordingPinCreate {
  pin: string;
}

export interface RecordingPinUpdate {
  pin?: string;
  user?: string;
}

// API functions
export const recordingsApi = {
  getAll: () =>
    customApi
      .get<Recording[]>("/recordings/")
      .then((response) => response.data),

  getOne: (id: string) =>
    customApi
      .get<Recording>(`/recordings/${id}/`)
      .then((response) => response.data),

  create: (data: RecordingCreate) =>
    customApi
      .post<Recording>("/recordings/", data)
      .then((response) => response.data),

  update: (id: string, data: Partial<Recording>) =>
    customApi
      .put<Recording>(`/recordings/${id}/`, data)
      .then((response) => response.data),

  delete: (id: string) =>
    customApi.delete(`/recordings/${id}/`).then((response) => response.data),

  uploadAudio: (id: string, file: File) => {
    const formData = new FormData();
    formData.append("audio", file);
    return customApi
      .post<AudioFile>(`/recordings/${id}/upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  },
};

export const recordingPinsApi = {
  getAll: () =>
    customApi
      .get<RecordingPin[]>("/recordings/pins/")
      .then((response) => response.data),

  getOne: (id: string) =>
    customApi
      .get<RecordingPin>(`/recording/pins/${id}/`)
      .then((response) => response.data),

  create: (data: RecordingPinCreate) =>
    customApi
      .post<RecordingPin>("/recordings/pins/", data)
      .then((response) => response.data),

  update: (id: string, data: RecordingPinUpdate) =>
    customApi
      .put<RecordingPin>(`/recording/pins/${id}/`, data)
      .then((response) => response.data),

  delete: (id: string) =>
    customApi
      .delete(`/recording/pins/${id}/`)
      .then((response) => response.data),
};

// New summariesApi
export const summariesApi = {
  update: (id: string, data: Partial<Summary>) =>
    customApi
      .patch<Summary>(`/recordings/summaries/${id}/`, data)
      .then((response) => response.data),
  
  // If you need to get a summary, you can add this method
  getOne: (id: string) =>
    customApi
      .get<Summary>(`/recordings/summaries/${id}/`)
      .then((response) => response.data),
  
  // If you need to get all summaries (though this isn't in your current backend)
  getAll: () =>
    customApi
      .get<Summary[]>('/recordings/summaries/')
      .then((response) => response.data),
};