import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import logo from "../assets/images/Logo.svg";
import { MicrophoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

const AppsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-1 flex flex-col items-stretch justify-between p-8 bg-blue-50">
      <div className="flex items-center justify-center mt-12 mb-12">
        <img className="h-10 w-auto mr-5" src={logo} alt="DentoAI" />
        <span className="text-3xl font-light border-l-2 border-gray-950 pl-5">
          Apps
        </span>
      </div>

      <main className="flex flex-col md:flex-row gap-8 w-full min-h-48 max-w-4xl self-center">
        <div
          className="flex-1 flex flex-col items-center justify-center p-6 cursor-pointer bg-white shadow-sm rounded-lg border border-slate-200 hover:shadow-md transition-shadow"
          onClick={() => navigate("/recordings")}
        >
          <MicrophoneIcon className="w-16 h-16 mb-4 text-cyan-500" />
          <h2 className="text-2xl font-light">AI Scribe</h2>
        </div>

        <div
          className="flex-1 flex flex-col items-center justify-center p-6 cursor-pointer bg-white shadow-sm rounded-lg border border-slate-200 hover:shadow-md transition-shadow"
          onClick={() => navigate("/email")}
        >
          <EnvelopeIcon className="w-16 h-16 mb-4 text-cyan-500" />
          <h2 className="text-2xl font-light">Email AI Copilot</h2>
        </div>
      </main>

      <footer className="mt-12 flex justify-center">
        <Button variant="link" onClick={() => navigate("/logout")}>
          Logout
        </Button>
      </footer>
    </div>
  );
};

export default AppsPage;
