import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { EmailAccount } from "../services/api";

interface OAuthCredentialStore {
  emailAccounts: EmailAccount[];
  setEmailAccounts: (emailAccounts: EmailAccount[]) => void;
  addEmailAccount: (emailAccount: EmailAccount) => void;
  updateEmailAccount: (
    id: number,
    updatedEmailAccount: Partial<EmailAccount>
  ) => void;
  deleteEmailAccount: (id: number) => void;
}

export const useOAuthCredentialStore = create<OAuthCredentialStore>()(
  immer((set) => ({
    emailAccounts: [],
    setEmailAccounts: (emailAccounts) =>
      set((state) => {
        state.emailAccounts = emailAccounts;
      }),
    addEmailAccount: (emailAccount) =>
      set((state) => {
        state.emailAccounts.push(emailAccount);
      }),
    updateEmailAccount: (id, updatedEmailAccount) =>
      set((state) => {
        const index = state.emailAccounts.findIndex((ea) => ea.id === id);
        if (index !== -1) {
          Object.assign(state.emailAccounts[index], updatedEmailAccount);
        }
      }),
    deleteEmailAccount: (id) =>
      set((state) => {
        state.emailAccounts = state.emailAccounts.filter((ea) => ea.id !== id);
      }),
  }))
);
