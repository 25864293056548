import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import logo from "../../assets/images/Logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { summariesApi } from "@/services/api";
import { Skeleton } from "@/components/ui/skeleton";
import ReactQuill from "react-quill";
import { Button } from "@/components/ui/button";
import BottomBar from "@/components/BottomBar";

const SummaryViewPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const id = params.id;
  if (!id) throw new Error("No ID provided");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [summaryType, setSummaryType] = useState<string>("");
  const [summaryContent, setSummaryContent] = useState<string>("");
  const quillRef = useRef<ReactQuill>(null);

  const quillModules = {
    toolbar: false, // This disables the toolbar
  };

  const handleBackButton = async () => {
    try {
      navigate(-1);
    } catch (error) {
      console.error("Error renavigating:", error);
    }
  };

  const handleQuillChange = (content: string) => {
    setSummaryContent(content);
  };

  useEffect(() => {
    async function fetchSummary() {
      const fetchedSummary = await summariesApi.getOne(id || "");
      setSummaryType(fetchedSummary.type);
      setSummaryContent(fetchedSummary.text);
      setIsLoading(false);
    }
    fetchSummary();
  }, [id]);

  return (
    <>
      <div className="bg-blue-50 w-full h-full p-2 pt-4 sm:pt-8 pb-52">
        <ReactQuill
          id="summary-content"
          className="flex flex-col h-full min-h-[1325px] mt-4 bg-white max-w-5xl mx-auto shadow-md p-8"
          ref={quillRef}
          readOnly={true}
          modules={quillModules}
          value={summaryContent}
          onChange={(content, delta, source, editor) => {
            if (source === "user") {
              handleQuillChange(content);
            }
          }}
        />
      </div>
      <div className="flex justify-center w-full p-4 sm:p-8 fixed bottom-0 z-10">
        <Button
          className="shadow-lg w-full max-w-sm self-center"
          variant="default"
          onClick={() => navigate(-1)}
          disabled={isLoading}
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default SummaryViewPage;
