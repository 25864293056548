import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const FormSchema = z.object({
  summary: z.string().min(10, {
    message: "Summary must be at least 10 characters.",
  }),
});

const PinSchema = z.object({
  pin: z.string().length(4, { message: "PIN must be 4 characters" }),
});

const RecordingPatientSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPinPopup, setShowPinPopup] = useState(false);
  const [summaries, setSummaries] = useState({
    REFERRING_DENTIST: "",
    PATIENT: "",
    EHR: "",
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      summary: "",
    },
  });

  const pinForm = useForm<z.infer<typeof PinSchema>>({
    resolver: zodResolver(PinSchema),
    defaultValues: {
      pin: "",
    },
  });

  useEffect(() => {
    // Simulating a GET request. Replace this with an actual API call
    setTimeout(() => {
      setSummaries({
        REFERRING_DENTIST: `Dear Dr. Smith,

Thank you for referring Patient to our practice. I am writing to provide you with a summary of the dental visit.

The transcript provided only contains the word 'recordings', which is insufficient for generating a meaningful dental visit summary. 

Chief Complaint: N/A
Dental Classification: N/A
Maxillary Dentition: N/A
Mandibular Dentition: N/A
Crossbite: N/A
Overjet: N/A
Overbite: N/A
Habits: N/A
Periodontal Needs: N/A
Hygiene Recall Frequency: N/A
Recommended Orthodontic Treatment: N/A
Extraction: N/A
Retention: N/A
Treatment Length: N/A

Please let me know if you have any questions or require additional information.`,
        PATIENT: "Dummy patient summary",
        EHR: "Dummy EHR summary",
      });
      setIsLoading(false);
    }, 1000);
  }, []);

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    console.log("submitted", data);
    setIsEditing(false);
  };

  const onPinSubmit = async (data: z.infer<typeof PinSchema>) => {
    // Simulate API call
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (data.pin === "1234") {
      console.log("success");
      setShowPinPopup(false);
      pinForm.reset();
    } else {
      pinForm.setError("pin", { type: "manual", message: "Incorrect PIN" });
    }
  };

  return (
    <div className="flex justify-center items-center h-full w-full p-2 sm:p-4 md:p-6 bg-gray-50">
      <Card className="w-full h-full relative flex flex-col justify-between">
        <div
          className="absolute flex justify-center items-center z-10 top-0 right-0 sm:top-2 sm:right-2 h-12 w-12 cursor-pointer"
          onClick={() => setShowPinPopup(true)}
        >
          <X className="h-8 w-8 hover:h-12 hover:w-12 active:w-6 active:h-6 text-slate-500 hover:text-slate-950 active:text-slate-500 transition-all" />
        </div>
        <CardHeader>
          <CardTitle>Patient Summary</CardTitle>
        </CardHeader>
        <CardContent className="flex-grow flex flex-col">
          <Tabs defaultValue="REFERRING_DENTIST" className="w-full h-full">
            <TabsList>
              <TabsTrigger value="REFERRING_DENTIST">
                Referring Dentist
              </TabsTrigger>
              <TabsTrigger value="PATIENT">Patient</TabsTrigger>
              <TabsTrigger value="EHR">EHR</TabsTrigger>
            </TabsList>
            {Object.entries(summaries).map(([key, value]) => (
              <TabsContent key={key} value={key} className="h-full">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="flex flex-col h-full"
                  >
                    <FormField
                      control={form.control}
                      name="summary"
                      render={({ field }) => (
                        <FormItem className="flex flex-col h-full">
                          <FormLabel>
                            Summary for {key.toLowerCase().replace("_", " ")}:
                          </FormLabel>
                          <FormControl className="flex-grow">
                            <Textarea
                              placeholder={`${key
                                .toLowerCase()
                                .replace("_", " ")} summary`}
                              className="resize-none h-full"
                              disabled={!isEditing || isLoading}
                              {...field}
                              value={value}
                              onChange={(e) => {
                                field.onChange(e);
                                setSummaries((prev) => ({
                                  ...prev,
                                  [key]: e.target.value,
                                }));
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>
              </TabsContent>
            ))}
          </Tabs>
        </CardContent>
        <CardFooter className="flex justify-end space-x-4">
          <Button
            type="button"
            variant="outline"
            onClick={() => setIsEditing(!isEditing)}
            disabled={isLoading}
          >
            {isEditing ? "Stop Editing" : "Edit"}
          </Button>
          <Button
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
            disabled={isEditing || isLoading}
          >
            Submit
          </Button>
        </CardFooter>
      </Card>

      {showPinPopup && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
          <Card className="sm:m-6">
            <CardHeader>
              <CardTitle>Enter PIN</CardTitle>
            </CardHeader>
            <CardContent>
              <Form {...pinForm}>
                <form
                  onSubmit={pinForm.handleSubmit(onPinSubmit)}
                  className="space-y-4"
                >
                  <FormField
                    control={pinForm.control}
                    name="pin"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>PIN</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter 4-digit PIN"
                            {...field}
                            maxLength={4}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </form>
              </Form>
            </CardContent>
            <CardFooter className="flex justify-end space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowPinPopup(false)}
              >
                Cancel
              </Button>
              <Button type="submit" onClick={pinForm.handleSubmit(onPinSubmit)}>
                Submit
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </div>
  );
};

export default RecordingPatientSummaryPage;
