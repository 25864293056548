import React, { useState, useEffect, useRef } from "react";
import { Trash2, ArrowLeft, ArrowRight } from "lucide-react";
import EmailList from "./EmailList";
import EmailLoading from "./EmailLoading";
import {
  backgroundJobApi,
  EmailExample,
  RawEmailData,
  recentEmailsApi,
} from "@/services/api";
import { Button } from "@/components/ui/button";

interface InboxPreviewProps {
  onBack: () => void;
  onNext: () => void;
}

const formatEmails = (rawEmails: RawEmailData[]): EmailExample[] => {
  return rawEmails.map((rawEmail) => {
    const emailData: Partial<EmailExample> = {};
    rawEmail.forEach(([key, value]) => {
      switch (key) {
        case "sender":
          emailData.sender = value;
          break;
        case "subject":
          emailData.subject = value;
          break;
        case "body":
          emailData.body = value;
          break;
        case "thread_id":
          emailData.threadId = value;
          break;
        case "labels":
          emailData.labels = Array.isArray(value) ? value : [];
          break;
      }
    });
    return emailData as EmailExample;
  });
};

const InboxPreview: React.FC<InboxPreviewProps> = ({ onBack, onNext }) => {
  const [emails, setEmails] = useState<EmailExample[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const apiCallMadeRef = useRef(false);

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout;

    const fetchEmails = async () => {
      if (apiCallMadeRef.current) return;

      try {
        setLoading(true);
        apiCallMadeRef.current = true;
        await recentEmailsApi.getRecentEmails(true);

        const pollBackgroundJobs = async () => {
          const backgroundJobs = await backgroundJobApi.getAll();
          const sortedJobs = backgroundJobs.sort(
            (a, b) =>
              new Date(b.modified).getTime() - new Date(a.modified).getTime()
          );
          const latestInboxPreviewJob = sortedJobs.find(
            (job) => job.process_name === "inbox_preview"
          );

          if (
            latestInboxPreviewJob &&
            latestInboxPreviewJob.status === "SUCCEEDED"
          ) {
            clearInterval(pollingInterval);
            const result = await recentEmailsApi.getPreview();
            if (Array.isArray(result)) {
              const formattedEmails = formatEmails(result as RawEmailData[]);
              setEmails(formattedEmails);
              setError(null);
            } else {
              setError("Invalid data format received from the server.");
            }
            setLoading(false);
          }
        };

        pollingInterval = setInterval(pollBackgroundJobs, 1500);
      } catch (err) {
        setError("Failed to fetch emails. Please try again later.");
        console.error(err);
        setLoading(false);
      }
    };

    fetchEmails();

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, []);

  if (loading) {
    return (
      <EmailLoading loadingMessage="Loading inbox preview..." hideLogo={true} />
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-4">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 flex flex-col">
      <div className="p-8 bg-slate-50 rounded-lg border border-gray-200">
        <h2 className="text-2xl font-bold mb-4">Inbox Preview</h2>
        <EmailList
          emails={emails}
          actionButtonClassName={() => "bg-red-600 hover:bg-red-700"}
          actionButtonIconClassName={() => "text-white"}
          emailNotEditable={true}
        />
      </div>
      <div className="mt-8 flex justify-between items-center">
        <Button
          onClick={onBack}
          variant="outline"
          className="flex items-center space-x-2"
        >
          <ArrowLeft className="w-4 h-4" />
          <span>Back</span>
        </Button>
        <Button
          onClick={onNext}
          variant="default"
          className="flex items-center space-x-2"
        >
          <span>Done</span>
          <ArrowRight className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};

export default InboxPreview;
