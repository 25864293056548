import React from "react";

function TermsOfService(): JSX.Element {
  return (
    <div className="terms-of-service larger-content">
      <h1 className="text-3xl font-semibold mb-4">Terms Of Service</h1>

      <p className="mb-4">
        1. <strong>Acceptance of Terms</strong>
      </p>
      <p>
        By accessing or using Dento AI ("we," "us," or "our"), you agree to
        comply with and be bound by these Terms of Service. If you do not agree
        with these terms, please do not use our platform.
      </p>

      <p className="mb-4 mt-4">
        2. <strong>Eligibility</strong>
      </p>
      <p>
        You must be at least [age] years old and have the legal capacity to
        enter into these Terms to use our platform. By using our platform, you
        represent and warrant that you meet these requirements.
      </p>

      <p className="mb-4 mt-4">
        3. <strong>Account Registration</strong>
      </p>
      <p>
        You may need to create an account to access certain features of our
        platform. When registering, you agree to provide accurate and complete
        information. You are responsible for maintaining the confidentiality of
        your account credentials and for all activities that occur under your
        account.
      </p>

      <p className="mb-4 mt-4">
        4. <strong>User Conduct</strong>
      </p>
      <p>By using our platform, you agree:</p>
      <ul className="list-disc list-inside pl-4 mb-4">
        <li>Not to violate any applicable laws or regulations.</li>
        <li>
          Not to engage in any fraudulent, abusive, or harmful activities.
        </li>
        <li>Not to impersonate another person or entity.</li>
        <li>Not to harass, threaten, or harm other users.</li>
        <li>
          Not to use our platform for any illegal or unauthorized purpose.
        </li>
        <li>
          Not to upload, post, or transmit any content that infringes on
          intellectual property rights or violates any rights of others.
        </li>
      </ul>

      <p className="mb-4 mt-4">
        5. <strong>Content</strong>
      </p>
      <p>
        You retain ownership of any content you upload, post, or share on our
        platform. However, you grant us a worldwide, non-exclusive,
        royalty-free, transferable, and sublicensable right to use, reproduce,
        distribute, modify, adapt, publicly display, and perform such content in
        connection with our platform.
      </p>

      <p className="mb-4 mt-4">
        6. <strong>Termination</strong>
      </p>
      <p>
        We reserve the right to suspend or terminate your account or access to
        our platform at our sole discretion, without notice, for any reason,
        including if you violate these Terms of Service.
      </p>

      <p className="mb-4 mt-4">
        7. <strong>Disclaimers</strong>
      </p>
      <p>
        Our platform is provided "as is" and "as available," without any
        warranties or representations, express or implied. We do not guarantee
        the accuracy, reliability, or availability of our platform.
      </p>

      <p className="mb-4 mt-4">
        8. <strong>Limitation of Liability</strong>
      </p>
      <p>
        To the extent permitted by applicable law, we will not be liable for any
        indirect, consequential, special, or incidental damages arising out of
        or in connection with your use of our platform.
      </p>

      <p className="mb-4 mt-4">
        9. <strong>Governing Law and Jurisdiction</strong>
      </p>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of California. You agree to submit to the exclusive
        jurisdiction of the courts in California for the resolution of any
        disputes arising from these terms.
      </p>

      <p className="mb-4 mt-4">
        10. <strong>Changes to these Terms</strong>
      </p>
      <p>
        We may update these Terms of Service to reflect changes in our platform
        or legal requirements. Please check this page regularly for updates. If
        we make significant changes, we will notify you through the platform or
        other means.
      </p>

      <p className="mb-4 mt-4">
        11. <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions, concerns, or requests regarding these Terms
        of Service, please contact us at{" "}
        <a
          href="mailto:team@womenfoundersbay.com"
          className="text-blue-500 hover:underline"
        >
          team@womenfoundersbay.com
        </a>
        .
      </p>
    </div>
  );
}

export default TermsOfService;
