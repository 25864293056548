import React from "react";
import { CheckIcon } from "lucide-react";

interface Step {
  name: string;
  status: "complete" | "current" | "upcoming";
}

interface StepsProps {
  steps: Step[];
  className?: string;
}

export function Steps({ steps, className }: StepsProps) {
  return (
    <nav
      aria-label="Progress"
      className={`w-full max-w-xl mx-auto bg-slate-50 border-2 border-slate-200 rounded-lg ${className}`}
    >
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative flex-1 flex">
            {step.status === "complete" ? (
              <div className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-cyan-600 rounded-full">
                    <CheckIcon className="w-6 h-6 text-white" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-slate-900">
                    {step.name}
                  </span>
                </span>
              </div>
            ) : step.status === "current" ? (
              <div
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-cyan-600 bg-white rounded-full">
                  <span className="text-cyan-600">{stepIdx + 1}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-cyan-600">
                  {step.name}
                </span>
              </div>
            ) : (
              <div className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300  bg-white rounded-full">
                    <span className="text-slate-500">{stepIdx + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">
                    {step.name}
                  </span>
                </span>
              </div>
            )}
            {stepIdx !== steps.length - 1 ? (
              <div
                className="hidden md:block absolute top-0 right-0 h-full w-5"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-slate-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
