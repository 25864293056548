import React, { useContext, useEffect, useState } from "react";
import { Grid, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useNavigate } from "react-router-dom";
import { recordingsApi, Recording } from "@/services/api";
import logo from "@/assets/images/Logo.svg";
import { useOAuthCredentialStore } from "@/store/useOAuthCredentialStore";
import { AuthContext } from "@/store/AuthContext";

const RecordingsPage: React.FC = () => {
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const fetchRecordings = async () => {
      try {
        const data = await recordingsApi.getAll();
        console.log(JSON.stringify(data));
        setRecordings(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchRecordings();
  }, []);

  const handleCreateNewRecording = () => {
    navigate("/recordings/new");
  };

  const handleRecordingClick = (id: string) => {
    navigate(`/recordings/view/${id}`);
  };

  return (
    <div className="p-12 flex-1 bg-blue-50">
      <div className="flex items-center justify-between mt-6 mb-12">
        <div className="flex items-center">
          <img className="h-8 w-auto mr-5" src={logo} alt="DentoAI" />
          <span className="text-2xl font-light border-l-2 border-gray-950 pl-5">
            AI Scribe
          </span>
        </div>
        <div className="flex gap-1">
          <Button
            variant="outline"
            onClick={() => navigate("/apps")}
            className="mr-4"
          >
            <Grid className="mr-2 h-4 w-4" />
            Home
          </Button>
          <Button variant="outline" onClick={() => logout()} className="">
            Logout
          </Button>
        </div>
      </div>
      <Card className="mt-8 flex-1">
        <CardHeader>
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">All Recordings</h3>
            <Button onClick={handleCreateNewRecording}>
              <Plus className="mr-2 h-4 w-4" />
              New Recording
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          {recordings.length === 0 ? (
            <Alert className="bg-slate-50">
              <AlertTitle>No recordings found</AlertTitle>
              <AlertDescription>
                Create a new recording to get started.
              </AlertDescription>
            </Alert>
          ) : (
            <div className="space-y-4">
              {recordings.map((recording) => (
                <div
                  key={recording.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-100 transition-colors"
                  onClick={() => handleRecordingClick(recording.id)}
                >
                  <div>
                    <p className="font-medium">{recording.title}</p>
                    <p className="text-sm text-gray-500">
                      {recording.patient_first_name}{" "}
                      {recording.patient_last_name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default RecordingsPage;
