import React, { useState, useEffect, useContext } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  BellIcon,
  MoonIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { AuthContext } from "@/store/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";



interface UserMenuItem {
  name: string;
  href: string;
}

const userMenuItems: UserMenuItem[] = [
  // { name: "Your Profile", href: "#" },
  { name: "Sign out", href: "/login" },
];

export default function AvatarHeader(): JSX.Element {
  const navigate = useNavigate();

  const { userDetails, logout } = useContext(AuthContext);
  
  const handleLogout = (): void => {
    logout();
    navigate("/login");
  };


  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4 mr-4">
            {/* <button className="text-gray-500 hover:text-gray-600">
              <BellIcon className="h-6 w-6" />
            </button>
            <button className="text-gray-500 hover:text-gray-600">
              <MoonIcon className="h-6 w-6" />
            </button> */}
          </div>

          <Popover className="relative">
            <PopoverButton className="flex gap-2 items-center text-sm font-medium text-gray-700 hover:text-gray-800">
              <img
                className="h-10 w-10 rounded-full"
                src={userDetails?.picture_url}
                alt=""
              />
              <div className="flex flex-col items-start">
                
                <span className="text-gray-900">{userDetails?.first_name + " " + userDetails?.last_name}</span>
                <span className="text-gray-500">{userDetails?.email}</span>
              </div>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                {userMenuItems.map((item) => (
                  <button
                  key={item.name}
                  onClick={handleLogout}
                  className="block w-full text-left p-2 hover:text-indigo-600"
                >
                  {item.name}
                </button>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
        </div>
      </div>
    </div>
  );
}
