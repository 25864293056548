import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import EmailTypesPage from "@/pages/email/EmailTypesPage";
import { CheckCircle, Inbox } from "lucide-react";

interface EmailTypesOnboardingProps {
  onComplete: () => void;
  onPreviewAgain: () => void;
}

export default function EmailOnboardingCustomize({
  onComplete,
  onPreviewAgain,
}: EmailTypesOnboardingProps): JSX.Element {
  return (
    <div className="flex flex-col items-center space-y-8 max-w-7xl mx-auto">
      {/* Email Types Content */}
      <div className="w-full">
        <EmailTypesPage />
      </div>

      <div className="w-full px-4 sm:px-6 lg:px-8 flex items-center space-x-4">
        <Button
          onClick={onPreviewAgain}
          variant="outline"
          className="w-full px-8 py-3 rounded-md flex items-center justify-center space-x-2"
        >
          <Inbox className="w-4 h-4" />
          <span>Preview Again</span>
        </Button>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={onComplete}
                className="w-full px-8 py-3 bg-cyan-600 text-white rounded-md hover:bg-cyan-700 transition-colors flex items-center justify-center space-x-2"
              >
                <CheckCircle className="w-4 h-4" />
                <span>Start Applying & Go To Inbox</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                All your emails will be AI enabled and organized by category
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
}
