import React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import logo from "../assets/images/Logo.svg";

interface EmailLoadingProps {
  loadingMessage?: string;
  hideLogo?: boolean;
}

const EmailLoading: React.FC<EmailLoadingProps> = ({
  loadingMessage,
  hideLogo = false,
}) => {
  const colorLabels = [
    "bg-blue-200",
    "bg-green-200",
    "bg-yellow-200",
    "bg-red-200",
    "bg-purple-200",
  ];

  return (
    <div className="flex flex-col items-stretch w-8/12 m-auto space-y-4 p-4">
      {!hideLogo && (
        <div className="flex self-center px-4 pt-6 pb-2">
          <img className="h-8 w-auto" src={logo} alt="DentoAI" />
        </div>
      )}

      {/* Loading message */}
      <div className="text-center mb-8 font-bold">{loadingMessage}</div>

      {[...Array(7)].map((_, index) => (
        <div
          key={index}
          className="flex items-center space-x-4 border p-3 rounded-md bg-white"
        >
          <div className="flex-1 flex items-center space-x-4">
            {/* Subject */}
            <Skeleton className="h-6 w-1/4" />

            {/* Color label (only for some emails) */}
            {index % 2 === 0 && (
              <Skeleton
                className={`w-40 h-6 rounded-full ${
                  colorLabels[index % colorLabels.length]
                }`}
              />
            )}

            {/* Body preview */}
            <Skeleton className="h-6 flex-1" />
          </div>

          {/* Action button */}
          <Skeleton className="h-6 w-6 rounded-full" />
        </div>
      ))}
    </div>
  );
};

export default EmailLoading;
