import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { EmailExample } from "@/services/api";
import { InputWithInsetLabel } from "./ui/inputWithInsetLabel";
import { TextareaWithInsetLabel } from "./ui/textareaWithInsetLabel";
import { useEmailTypeStore } from "@/store/useEmailTypeStore";
import { colorPairings } from "@/lib/colorContrastUtils";

interface EmailListProps {
  emails: EmailExample[];
  onUpdateEmail?: (
    index: number,
    field: keyof EmailExample,
    value: string
  ) => void;
  actionIcon?: (props: { index: number; className: string }) => React.ReactNode;
  onActionClick?: (index: number, e: React.MouseEvent) => void;
  actionButtonClassName?: (index: number) => string;
  actionButtonIconClassName?: (index: number) => string;
  emailNotEditable?: boolean;
}

const EmailList: React.FC<EmailListProps> = ({
  emails,
  onUpdateEmail,
  actionIcon,
  onActionClick,
  actionButtonClassName,
  actionButtonIconClassName,
  emailNotEditable = false,
}) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [subjectWidth, setSubjectWidth] = useState<number>(0);
  const containerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const emailTypes = useEmailTypeStore((state) => state.emailTypes);

  const toggleAccordion = (value: string) => {
    setExpandedItems((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  useEffect(() => {
    const updateWidths = () => {
      const containerWidth = containerRefs.current[0]?.offsetWidth || 0;
      const buttonWidth = actionIcon ? 40 : 0;
      const spacing = 24;
      const availableWidth = containerWidth - buttonWidth - spacing;

      setSubjectWidth(Math.max(150, Math.min(300, availableWidth * 0.3)));
    };

    updateWidths();
    window.addEventListener("resize", updateWidths);

    return () => {
      window.removeEventListener("resize", updateWidths);
    };
  }, [actionIcon]);

  const renderLabels = (labels: string[]) => {
    return (
      <div className="flex items-center overflow-hidden flex-shrink-0 mr-2">
        {labels.map((label) => {
          const emailType = emailTypes.find((et) => et.title === label);
          if (!emailType) return null;

          const bgColor = emailType.color;
          const textColor = colorPairings[bgColor] || "#000000";

          return (
            <span
              key={label}
              className="inline-block px-2 py-0.5 rounded-sm text-xs mr-1 whitespace-nowrap"
              style={{
                backgroundColor: bgColor,
                color: textColor,
              }}
            >
              {label}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <Accordion
      type="multiple"
      value={expandedItems}
      onValueChange={setExpandedItems}
      className="w-full space-y-2 max-w-full overflow-hidden"
    >
      {emails.map((email, index) => (
        <AccordionItem
          key={index}
          value={`example-${index}`}
          className="border rounded-md shadow-sm bg-white overflow-hidden"
        >
          <AccordionTrigger
            onClick={() => toggleAccordion(`example-${index}`)}
            className="hover:no-underline py-2 px-3 w-full"
          >
            <div
              ref={(el) => (containerRefs.current[index] = el)}
              className="flex items-center w-full overflow-hidden text-left"
            >
              <div className="flex-1 mr-2 overflow-hidden flex items-center min-w-0">
                <span
                  className="font-medium text-sm mr-3 truncate flex-shrink-0"
                  style={{ width: `${subjectWidth}px` }}
                >
                  {email.subject || "No Subject"}
                </span>
                <div className="flex-1 min-w-0 flex items-center overflow-hidden">
                  {email.labels &&
                    email.labels.length > 0 &&
                    renderLabels(email.labels)}
                  <span className="text-sm font-light text-gray-500 truncate flex-grow">
                    {email.body}
                  </span>
                </div>
              </div>
              {actionIcon && onActionClick && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onActionClick(index, e);
                  }}
                  className={`rounded-full p-2 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 flex-shrink-0 ${
                    actionButtonClassName ? actionButtonClassName(index) : ""
                  }`}
                >
                  {actionIcon({
                    index,
                    className: `h-4 w-4 ${
                      actionButtonIconClassName
                        ? actionButtonIconClassName(index)
                        : ""
                    }`,
                  })}
                </button>
              )}
            </div>
          </AccordionTrigger>
          <AccordionContent className="overflow-hidden">
            {emailNotEditable ? (
              <div className="px-6 py-2">
                <p className="text-sm text-gray-700 whitespace-pre-wrap">
                  {email.body}
                </p>
              </div>
            ) : (
              <div className="space-y-3 p-3 rounded-md">
                <InputWithInsetLabel
                  label="Subject"
                  value={email.subject}
                  onChange={(e) =>
                    onUpdateEmail &&
                    onUpdateEmail(index, "subject", e.target.value)
                  }
                  placeholder="Subject"
                />
                <TextareaWithInsetLabel
                  label="Body"
                  value={email.body}
                  onChange={(e) =>
                    onUpdateEmail &&
                    onUpdateEmail(index, "body", e.target.value)
                  }
                  placeholder="Email body"
                  className="w-full min-h-[100px] p-2 border rounded"
                />
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default EmailList;
