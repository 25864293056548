import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Play, Pause, Square, Loader2, Mic, MicOff } from "lucide-react";
import { AuthContext } from "../../store/AuthContext";
import api from "../../services/customApiInstance";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useToast } from "@/components/ui/use-toast"


import { Alert, AlertDescription } from "@/components/ui/alert";
import logo from "../../assets/images/Logo.svg";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { useForm, ControllerRenderProps, FieldValues } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { recordingsApi, Recording } from "@/services/api";
import { PauseIcon } from "@heroicons/react/20/solid";

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
});

type FormValues = z.infer<typeof formSchema>;

function RecordingCreatePage () {
    // [~Max thots~]
    // Get the first name and last name from the user
    // submit api request to create a new recording object in db
    // get the id -->  means wwe need to have a recording state and pass "setRecording" to the name form component
    // the recording component will save audio until the user clicks the stop button, at which point it will kick off the audio upload endpoint and move to the next step
    // the next step is polling the recording object to see if the audio has been processed
    const [recordingId, setRecordingId] = useState<string>("");
    const [doneRecording, setDoneRecording] = useState<boolean>(false);

    if (recordingId == "") {
        return <RecordingNameFormComponent setRecordingId={setRecordingId} />
    } else if (!doneRecording) {
        return <RecordingMainComponent recordingId={recordingId} setDoneRecording={() => setDoneRecording(true)}/>
    } else {
        return <RecordingWaitComponent recordingId={recordingId}/>
    }

}


function RecordingWaitComponent({ recordingId }: { recordingId: string }) {
    const navigate = useNavigate();
    const [elapsedTime, setElapsedTime] = useState(0);
    const [recording, setRecording] = useState<Recording | null>(null);

    let message = "";
    if (!recording) {
        console.log("No recording object yet");
    } else if (!recording.transcript) {
        message = "Transcribing your recording";
    } else if (!recording.summaries || recording.summaries.length === 0) {
        message = "Creating visit summary";
    } else {
        navigate(`/recordings/view/${recording.id}`);
    }
  
    useEffect(() => {
      const pollInterval = setInterval(async () => {
        if (elapsedTime < 60) {
          try {
            const data = await recordingsApi.getOne(recordingId)
            setRecording(data);
            console.log('Polling result:', JSON.stringify(data));
          } catch (err) {
            console.error('Error polling recording:', err);
          }
          setElapsedTime(prevTime => prevTime + 1);
        } else {
          clearInterval(pollInterval);
          console.log('Polling stopped after 60 seconds');
        }
      }, 1000);
  
      return () => clearInterval(pollInterval);
    }, [recordingId, elapsedTime]);
  
    return (
      <div className="bg-blue-50 flex flex-col items-center justify-center min-h-screen min-w-full">
        <div className="flex mb-10">
          <img className="h-8 w-auto" src={logo} alt="DentoAI" />
        </div>
        <Card className="w-full max-w-xl p-6">
          <CardHeader>
            <CardTitle className="text-center mt-2 mb-4">
                {message}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-8">
            <div className="flex justify-center items-center h-full w-full bg-white">
              <div className="text-center">
                <Loader2 className="h-24 w-24 animate-spin text-slate-950" />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  function RecordingMainComponent({recordingId, setDoneRecording}: {recordingId: string, setDoneRecording: ()=>void}) {
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    
    // New state variables for the timer
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timerInterval, setTimerInterval] = useState<number | null>(null);

    const { toast } = useToast();

    useEffect(() => {
      if (isRecording && !isPaused) {
          const interval = window.setInterval(() => {
              setElapsedTime(prevTime => prevTime + 1);
          }, 1000);
          setTimerInterval(interval);
      } else {
          if (timerInterval) {
              window.clearInterval(timerInterval);
          }
      }

      return () => {
          if (timerInterval) {
              window.clearInterval(timerInterval);
          }
      };
  }, [isRecording, isPaused]);

    const formatTime = (timeInSeconds: number) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const startRecording = async () => {
        console.log("START!");
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const newMediaRecorder = new MediaRecorder(stream);
            
            newMediaRecorder.ondataavailable = (event) => {
                console.log("Data available");
                if (event.data.size > 0) {
                    console.log("Data size:", event.data.size);
                    setAudioChunks(chunks => [...chunks, event.data]);
                }
            };
    
            newMediaRecorder.start(100);
            setMediaRecorder(newMediaRecorder);

            setIsRecording(true);
            setIsPaused(false);
            setElapsedTime(0); // Reset timer when starting a new recording
        } catch (error) {
            toast({
              variant: "destructive",
              title: "Uh oh! Something went wrong.",
              description: "Enable microphone access in your computer's settings and browser permissions.",
              duration: 5000,
            })
            console.error("Error starting recording:", error);
        }
    };

    const pauseRecording = () => {
        console.log("PAUSE BUTTON");
        if (mediaRecorder && isRecording) {
            if (isPaused) {
                console.log("RESUME");
                mediaRecorder.resume();
            } else {
                console.log("PAUSE");
                mediaRecorder.pause();
            }
            setIsPaused(!isPaused);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.onstop = async () => {
                console.log("Recording stopped with chunks:", audioChunks.length);
                
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const audioFile = new File([audioBlob], 'recording.wav', { type: 'audio/wav' });
        
                try {
                    await recordingsApi.uploadAudio(recordingId, audioFile);
                    console.log("Audio uploaded successfully");
                    setDoneRecording();
                } catch (error) {
                    console.error("Error uploading audio:", error);
                }
        
                setAudioChunks([]);
                setIsRecording(false);
                setIsPaused(false);
                setElapsedTime(0); // Reset timer when stopping the recording
            };
        
            mediaRecorder.stop();
        }
    };

    return (
        <div className="bg-blue-50 flex flex-col items-center justify-center min-h-screen min-w-full">
            <div className="flex mb-10">
                <img className="h-8 w-auto" src={logo} alt="DentoAI" />
            </div>
            <Card className="w-full max-w-xl p-6">
                <CardHeader>
                    {isRecording ?
                        <CardTitle className="text-center mt-2 mb-4 flex flex-row gap-4 justify-between items-center">
                            <div className="flex flex-row gap-4">
                                {isPaused ?
                                    <>
                                        Recording paused
                                        <MicOff className="w-5 h-5 mr-2 text-slate-400" />
                                    </>
                                    :
                                    <>
                                        Recording in progress
                                        <Mic className="w-5 h-5 mr-2 animate-spin text-cyan-500" />
                                    </>
                                }
                            </div>
                        </CardTitle>
                        :
                        <CardTitle className="text-center mt-2 mb-4 items-center justify-center">Start Recording</CardTitle>
                    }
                </CardHeader>
                <CardContent className="flex flex-col gap-6">
                    {/* Timer display */}
                      <div className={`text-7xl text-center font-semibold ${!isRecording || isPaused ? 'text-slate-400' : 'text-black'}`}>
                        {formatTime(elapsedTime)}
                      </div>
                    

                    <div className="flex justify-around items-center h-full w-full">
                        <div className="text-center">
                            {!isRecording ? (
                                <Button 
                                    variant="outline" 
                                    size="icon" 
                                    className="group w-24 h-24 rounded-full bg-cyan-500 hover:bg-cyan-400 active:bg-cyan-600 drop-shadow-xl hover:drop-shadow-xl active:drop-shadow-lg transition-all"
                                    onClick={startRecording}
                                >
                                    <div className="h-10 w-10 bg-white rounded-full group-hover:h-12 group-hover:w-12 group-active:h-8 group-active:w-8 transition-all" />
                                </Button>
                            ) : (
                                <div className="space-x-4">
                                    <Button 
                                        variant="outline" 
                                        size="icon" 
                                        className="group w-16 h-16 rounded-full bg-cyan-500 hover:bg-cyan-400 active:bg-cyan-600 drop-shadow-xl hover:drop-shadow-xl active:drop-shadow-lg transition-all"
                                        onClick={pauseRecording}
                                    >
                                        {isPaused ? 
                                            <div className="h-6 w-6 group-hover:h-8 group-hover:w-8 group-active:h-4 group-active:w-4 bg-white rounded-full transition-all" />
                                        : 
                                            <PauseIcon className="h-8 w-8 group-hover:h-10 group-hover:w-10 group-active:h-6 group-active:w-6 text-slate-100 transition-all" />
                                        }
                                    </Button>
                                    <Button 
                                        variant="outline" 
                                        size="icon"
                                        className="group w-16 h-16 rounded-full bg-red-500 hover:bg-red-400 active:bg-red-600 drop-shadow-xl hover:drop-shadow-xl active:drop-shadow-lg transition-all"
                                        onClick={stopRecording}
                                    >
                                        <div className="h-6 w-6 rounded-sm group-hover:h-8 group-hover:w-8 group-active:h-4 group-active:w-4 bg-white transition-all" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}


function RecordingNameFormComponent ({setRecordingId}: {setRecordingId: (id: string) => void}) {

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { updateAuthStatus } = useContext(AuthContext);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
        const res = await recordingsApi.create({
            patient_first_name: values.firstName,
            patient_last_name: values.lastName,
        });
        setSuccessMessage("Name updated successfully");
        setRecordingId(res.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message || "An error occurred");
      } else {
        setErrorMessage("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-blue-50 flex flex-col items-center justify-center min-h-screen min-w-full">
      <div className="flex mb-10">
        <img className="h-8 w-auto" src={logo} alt="DentoAI" />
      </div>
      <Card className="w-full max-w-xl p-6">
        <CardHeader>
          <CardTitle className="text-center mt-2 mb-4">
            Register to continue
          </CardTitle>
        </CardHeader>
        <CardContent>
          {successMessage && (
            <Alert className="mb-4" variant="default">
              <AlertDescription>{successMessage}</AlertDescription>
            </Alert>
          )}
          {errorMessage && (
            <Alert className="mb-4" variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }: { field: ControllerRenderProps<FormValues, "firstName"> }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your first name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }: { field: ControllerRenderProps<FormValues, "lastName"> }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your last name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}

export default RecordingCreatePage;