import MicrosoftAuthButton from "@/components/MicrosoftAuthButton";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import React, { FormEvent, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/Logo.svg";
import GoogleAuthButton from "../components/GoogleAuthButton";
import { UserDetails } from "../services/authUtils";
import { AuthContext } from "../store/AuthContext";
import customApi from "@/services/customApiInstance";

interface RegistrationResponse {
  user: UserDetails;
  token: string;
}

function RegistrationPage(): JSX.Element {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const { updateAuthStatus } = useContext(AuthContext);

  const handleSuccessfulRegistration = (data: RegistrationResponse): void => {
    updateAuthStatus(data.user, data.token);
    navigate("/apps");
  };

  const handleSubmitRegistration = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Password and confirm password do not match");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email format");
      return;
    }

    try {
      const response = await customApi.post<{
        data: RegistrationResponse;
        status: number;
      }>(
        "/users/register/",
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          confirm_password: confirmPassword,
        },
        {
          authRequired: false,
        }
      );

      if (response.status === 201) {
        handleSuccessfulRegistration(response.data);
      } else {
        setErrorMessage("Unexpected response from the server");
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Error during registration");
      }
    }
  };
  const handleAuthSuccess = (data: RegistrationResponse): void => {
    handleSuccessfulRegistration(data);
  };

  const handleAuthFailure = (error: Error): void => {
    setErrorMessage("Authentication failed. Please try again.");
  };

  return (
    <div className="bg-gray-50 flex flex-col items-center justify-center min-h-screen min-w-full">
      <div className="flex mb-10">
        <img className="h-8 w-auto" src={logo} alt="DentoAI" />
      </div>
      <Card className="w-full max-w-xl p-6">
        <CardHeader>
          <CardTitle className="text-center mt-2 mb-4">
            Create an account to get started
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          {errorMessage && (
            <Alert variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          <div className="flex items-center justify-center space-x-4">
            <GoogleAuthButton
              onSuccess={handleAuthSuccess}
              onFailure={handleAuthFailure}
            />
            {/* <MicrosoftAuthButton
              onSuccess={handleAuthSuccess}
              onFailure={handleAuthFailure}
            /> */}
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                Or
              </span>
            </div>
          </div>

          {!showEmailForm ? (
            <>
              {/* <Button
              variant="outline"
              className="w-full"
              onClick={() => setShowEmailForm(true)}
            >
              Sign up with email
            </Button> */}
            </>
          ) : (
            <form onSubmit={handleSubmitRegistration} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="firstName">First Name</Label>
                  <Input
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="lastName">Last Name</Label>
                  <Input
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Email address</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirmPassword">Confirm Password</Label>
                <Input
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" className="w-full">
                Get Started
              </Button>
            </form>
          )}
        </CardContent>
        <CardFooter>
          <p className="text-sm text-center text-muted-foreground w-full">
            Already have an account?{" "}
            <Link to="/login" className="text-primary hover:underline">
              Sign in
            </Link>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}

export default RegistrationPage;
