export const backgroundColors = [
  // Reds
  ["#ac2b16", "#cc3a21", "#e66550", "#efa093", "#f6c5be"],
  // Oranges
  ["#a46a21", "#cf8933", "#ffbc6b", "#ffd6a2", "#ffe6c7"],
  // Yellows
  ["#aa8831", "#d5ae49", "#fcda83", "#fce8b3", "#fef1d1"],
  // Greens
  ["#076239", "#149e60", "#44b984", "#89d3b2", "#b9e4d0"],
  // Cyans
  ["#0b804b", "#2a9c68", "#68dfa9", "#a0eac9", "#c6f3de"],
  // Blues
  ["#1c4587", "#3c78d8", "#6d9eeb", "#a4c2f4", "#c9daf8"],
  // Purples
  ["#41236d", "#8e63ce", "#b694e8", "#d0bcf1", "#e4d7f5"],
  // Magentas
  ["#83334c", "#b65775", "#f7a7c0", "#fbc8d9", "#fcdee8"],
  // Grays
  [
    "#000000",
    "#434343",
    "#666666",
    "#999999",
    "#cccccc",
    "#efefef",
    "#f3f3f3",
    "#ffffff",
  ],
];

export const colorPairings: { [key: string]: string } = {
  // Reds
  "#ac2b16": "#F6C5BE",
  "#cc3a21": "#F6C5BE",
  "#e66550": "#822111",
  "#efa093": "#822111",
  "#f6c5be": "#822111",
  // Oranges
  "#a46a21": "#FFE6C7",
  "#cf8933": "#FFE6C7",
  "#ffbc6b": "#A46A21",
  "#ffd6a2": "#A46A21",
  "#ffe6c7": "#A46A21",
  // Yellows
  "#aa8831": "#FEF1D1",
  "#d5ae49": "#FEF1D1",
  "#fcda83": "#AA8831",
  "#fce8b3": "#AA8831",
  "#fef1d1": "#AA8831",
  // Greens
  "#076239": "#B9E4D0",
  "#149e60": "#B9E4D0",
  "#44b984": "#076239",
  "#89d3b2": "#076239",
  "#b9e4d0": "#076239",
  // Cyans
  "#0b804b": "#B9E4D0",
  "#2a9c68": "#C6F3DE",
  "#68dfa9": "#1A764D",
  "#a0eac9": "#1A764D",
  "#c6f3de": "#1A764D",
  // Blues
  "#1c4587": "#C9DAF8",
  "#3c78d8": "#C9DAF8",
  "#6d9eeb": "#1C4587",
  "#a4c2f4": "#1C4587",
  "#c9daf8": "#1C4587",
  // Purples
  "#41236d": "#E4D7F5",
  "#8e63ce": "#E4D7F5",
  "#b694e8": "#41236D",
  "#d0bcf1": "#41236D",
  "#e4d7f5": "#41236D",
  // Magentas
  "#83334c": "#FCDEE8",
  "#b65775": "#FCDEE8",
  "#f7a7c0": "#83334C",
  "#fbc8d9": "#83334C",
  "#fcdee8": "#83334C",
  // Grays
  "#000000": "#FFFFFF",
  "#434343": "#FFFFFF",
  "#666666": "#FFFFFF",
  "#999999": "#FFFFFF",
  "#cccccc": "#000000",
  "#efefef": "#000000",
  "#f3f3f3": "#000000",
  "#ffffff": "#000000",
};

export function generateColorPairings(): {
  background: string;
  text: string;
}[] {
  return backgroundColors.flat().map((bgColor) => ({
    background: bgColor,
    text: colorPairings[bgColor],
  }));
}
