import React, { useContext } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Squares2X2Icon,
  EnvelopeIcon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
  ChartBarIcon,
  LifebuoyIcon,
  ArrowRightOnRectangleIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/outline";
import logo from "../assets/images/Logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../store/AuthContext";
import { Grid } from "lucide-react";

interface NavigationItem {
  name: string;
  icon: React.ComponentType<any>;
  href: string;
  children?: NavigationItem[];
}

const navigation: NavigationItem[] = [
  { name: "Dashboard", icon: Squares2X2Icon, href: "/email/" },
  {
    name: "Categories",
    icon: EnvelopeIcon,
    href: "/email/types",
    children: [],
  },
  {
    name: "Accounts",
    icon: UserGroupIcon,
    href: "/email/accounts",
    children: [],
  },
];

const bottomNavigation: NavigationItem[] = [
  { name: "Home", icon: Grid, href: "/apps" },
  { name: "Support", icon: LifebuoyIcon, href: "/support" },
  { name: "Logout", icon: ArrowRightOnRectangleIcon, href: "#" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext); // Use the logout function from AuthContext

  const isActive = (href: string) => location.pathname === href;

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logout();
    navigate("/login");
  };

  return (
    <div className="flex flex-col justify-between bg-white min-w-[300px] border-r border-y border-gray-200">
      <div className="flex flex-col overflow-y-auto">
        <div className="flex px-4 pt-6 pb-2">
          <div className="flex items-center">
            <img className="h-5 w-auto mr-2" src={logo} alt="DentoAI" />
            <span className="text-lg font-light border-l border-gray-950 pl-2">
              Email AI Copilot
            </span>
          </div>
        </div>
        <nav className="mt-5 flex-1 space-y-1 px-2">
          {navigation.map((item) => (
            <Disclosure as="div" key={item.name} className="space-y-1">
              {({ open }) => (
                <>
                  {item.children && item.children.length > 0 ? (
                    <DisclosureButton
                      className={classNames(
                        isActive(item.href)
                          ? "bg-cyan-50 text-cyan-600"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-cyan-500"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          isActive(item.href)
                            ? "text-cyan-600"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 h-6 w-6 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-400 rotate-180" : "text-gray-300",
                          "ml-auto h-5 w-5 transform"
                        )}
                        aria-hidden="true"
                      />
                    </DisclosureButton>
                  ) : (
                    <DisclosureButton
                      as="a"
                      href={item.href}
                      className={classNames(
                        isActive(item.href)
                          ? "bg-cyan-50 text-cyan-600"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-cyan-500"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          isActive(item.href)
                            ? "text-cyan-600"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 h-6 w-6 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </DisclosureButton>
                  )}
                  {item.children && item.children.length > 0 && (
                    <DisclosurePanel className="space-y-1">
                      {item.children.map((subItem) => (
                        <DisclosureButton
                          key={subItem.name}
                          as="a"
                          href={subItem.href}
                          className={classNames(
                            isActive(subItem.href)
                              ? "bg-cyan-50 text-cyan-600"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-semibold"
                          )}
                        >
                          {subItem.name}
                        </DisclosureButton>
                      ))}
                    </DisclosurePanel>
                  )}
                </>
              )}
            </Disclosure>
          ))}
        </nav>
      </div>
      <div className="shrink-0 border-t border-gray-200 p-4">
        {bottomNavigation.map((item) => (
          <a
            key={item.name}
            href={item.href}
            onClick={item.name === "Logout" ? handleLogout : undefined}
            className={classNames(
              isActive(item.href)
                ? "bg-cyan-50 text-cyan-600"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center rounded-md py-2 px-2 text-sm font-semibold"
            )}
          >
            <item.icon
              className={classNames(
                isActive(item.href)
                  ? "text-cyan-600"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-3 h-6 w-6"
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
}
