import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import React from "react";

interface StatProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  value: string;
  percentage: number;
  menuAction?: () => void;
  className?: string;
}

const Stat: React.FC<StatProps> = ({
  icon: Icon,
  title,
  value,
  percentage,
  menuAction,
  className,
}) => {
  const isPositive: boolean = percentage >= 0;
  const percentageColor: string = isPositive
    ? "bg-green-100 text-green-800 border-green-200"
    : "bg-red-100 text-red-800 border-red-200";
  const arrow: string = isPositive ? "↑" : "↓";

  return (
    <div
      className={`bg-white rounded-xl p-6 max-w-sm border border-gray-200 ${className}`}
    >
      <div className="flex justify-between items-start">
        <div className="bg-cyan-500 rounded-full p-4 border-4 border-cyan-200">
          <Icon className="h-6 w-6 text-white" />
        </div>
        {menuAction && (
          <button
            onClick={menuAction}
            className="text-gray-400 hover:text-gray-600"
          >
            <EllipsisVerticalIcon className="h-6 w-6" />
          </button>
        )}
      </div>
      <h3 className="mt-4 text-gray-500 font-medium">{title}</h3>
      <div className="mt-2 flex items-baseline justify-between">
        <p className="text-4xl font-semibold text-gray-900">{value}</p>
        <span
          className={`${percentageColor} text-sm font-medium px-2.5 py-0.5 rounded-lg border`}
        >
          {arrow} {Math.abs(percentage)}%
        </span>
      </div>
    </div>
  );
};

export default Stat;
