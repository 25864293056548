import React from "react";

function NoPage(): JSX.Element {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Page Not Found</h2>
        <p className="text-gray-600">
          The page you are looking for doesn't exist.
        </p>
      </div>
    </div>
  );
}

export default NoPage;
