import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  authRequired?: boolean;
}

interface CustomInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  authRequired?: boolean;
}

const API_URL = process.env.REACT_APP_API_URL || "";

if (!API_URL) {
  console.warn("REACT_APP_API_URL is not set. API calls may fail.");
}

const api: AxiosInstance = axios.create({
  baseURL: `${API_URL}/api/`,
});

api.interceptors.request.use(
  (
    config: CustomInternalAxiosRequestConfig
  ): CustomInternalAxiosRequestConfig => {
    if (config.authRequired !== false) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Token ${token}`;
      }
    }
    config.withCredentials = true;
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      // Token is invalid or expired
      localStorage.removeItem("token");
      localStorage.removeItem("userDetails");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const customApi = api as AxiosInstance & {
  get<T = any, R = T>(
    url: string,
    config?: CustomAxiosRequestConfig
  ): Promise<R>;
  post<T = any, R = T, D = any>(
    url: string,
    data?: D,
    config?: CustomAxiosRequestConfig
  ): Promise<R>;
  // Add other methods (put, delete, etc.) as needed
};

export default customApi;
