import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "../store/AuthContext";
import SummaryEditPage from "@/pages/summary/SummaryEditPage";
import SummaryViewPage from "@/pages/summary/SummaryViewPage";

const SummaryRouter: React.FC = () => {
  const { userDetails } = useContext(AuthContext);

  if (!userDetails) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route path="/:id/edit" element={<SummaryEditPage />} />
      <Route path="/:id/view" element={<SummaryViewPage />} />

    </Routes>
  );
};

export default SummaryRouter;
