import React from "react";
import { Button } from "@/components/ui/button";
import { Play } from "lucide-react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo.svg";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";

const RecordingStartPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-50 flex flex-col items-center justify-center min-h-screen min-w-full">
      <div className="flex mb-10">
        <img className="h-8 w-auto" src={logo} alt="DentoAI" />
      </div>
      <Card className="w-full max-w-xl p-6">
        <CardHeader>
          <CardTitle className="text-center mt-2 mb-4">
            Start Recording
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          <div className="flex justify-center items-center h-full w-full bg-white">
            <div className="text-center">
              <Button
                variant="outline"
                size="icon"
                className="group w-24 h-24 rounded-full border-4 hover:border-4 active:border-8 border-slate-950 bg-red-500 hover:bg-red-400 active:bg-red-600 drop-shadow hover:drop-shadow-md active:drop-shadow-sm transition-all"
                onClick={() => {
                  // Implement recording logic here
                  navigate("/recordings/recording"); // FIXME: Replace this with Sinchan's recording screen
                }}
              >
                <Play className="h-12 w-12 group-hover:h-14 group-hover:w-14 group-active:h-10 group-active:w-10 text-slate-950 group-hover:text-slate-100 group-active:text-slate-950 transition-all" />
              </Button>
            </div>
          </div>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
};

export default RecordingStartPage;
