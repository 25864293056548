import { Toaster } from "@/components/ui/toaster";
import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavSidebar from "./components/NavSidebar";
import { AuthContext } from "./store/AuthContext";

const Layout = (): JSX.Element => {
  const { isAuthenticated, userDetails } = useContext(AuthContext);
  const location = useLocation();

  const isInOnboarding =
    userDetails?.onboarding_completed === false ||
    location.pathname === "/onboarding";

  const isEmailPath = location.pathname.startsWith("/email");

  const showNavSidebar = isAuthenticated && !isInOnboarding && isEmailPath;

  return (
    <>
      <div className="flex flex-col h-screen w-screen">
        <div className="flex flex-row flex-1 items-stretch">
          {showNavSidebar && <NavSidebar />}
          <Outlet />
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default Layout;
