import React, { useState, ChangeEvent, FormEvent } from "react";
import api from "../services/customApiInstance";
import { Link } from "react-router-dom";

// ForgotPasswordPage.tsx

function ForgotPasswordPage() {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const clearMessages = () => {
    setMessage("");
    setErrorMessage("");
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    clearMessages();

    try {
      const response = await api.post("/users/forgot-password/", {
        email: email,
      });

      if (response.status === 200 || response.status === 201) {
        setMessage("Password Reset Email Sent");
      } else {
        setErrorMessage(
          "Couldn't send the password reset email. Please try again"
        );
      }
    } catch (error) {
      setErrorMessage(
        "If the email is correct, we will send you instructions to reset your password."
      );
    }
  };

  return (
    <div className="main-content authentication-container">
      <h2 className="page-title">Forgot Password</h2>

      {message && <div className="message-success">{message}</div>}
      {errorMessage && <div className="message-error">{errorMessage}</div>}

      <form onSubmit={handleSubmit} className="form-container">
        <label className="form-label" htmlFor="email">
          Email:
        </label>
        <input
          className="form-input"
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button className="form-btn-submit" type="submit">
          Reset Password
        </button>
      </form>

      <div className="mt-2 text-center">
        <Link to="/login" className="link">
          Back to Sign In
        </Link>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
