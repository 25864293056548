import React, { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Recording, recordingsApi, summariesApi } from "@/services/api";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "@/styles/quill-custom.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import BottomBar from "@/components/BottomBar";

type SummaryType = "SOAP" | "REFERRING_DENTIST" | "PATIENT" | "EHR";
type SummaryContent = {
  [K in SummaryType]: string;
};
// Type guard function to check if a value is a valid SummaryType
function isSummaryType(value: string): value is SummaryType {
  return ["SOAP", "REFERRING_DENTIST", "PATIENT", "EHR"].includes(value);
}

const PinSchema = z.object({
  pin: z.string().length(4, { message: "PIN must be 4 characters" }),
});

const STUB_SOAP = {
  id: "01J5C5FFG6K2PBMW1J0DTFDX6C",
  text: `<p><strong class="ql-size-large">S: Subjective</strong></p>
<p><br></p>
<ul>
    <li><strong>Chief Complaint:</strong> "Missing front teeth from car accident"</li>
    <li><strong>Past Medical History:</strong>
        <ul>
            <li>High cholesterol managed with simvastatin</li>
            <li>Allergic to penicillin</li>
        </ul>
    </li>
    <li><strong>Dental History:</strong> Chronic periodontal disease, lost teeth #9, #10, and #11 in a car accident.</li>
    <li><strong>Social History:</strong> Social smoker, no reported alcohol or drug abuse.</li>
</ul>
<p><br></p>

<p><strong class="ql-size-large">O: Objective</strong></p>
<p><br></p>
<ul>
    <li><strong>Oral Examination:</strong>
        <ul>
            <li>Missing teeth #9 to #11, periodontal disease under control.</li>
        </ul>
    </li>
    <li><strong>Radiographic Findings:</strong> Vertical bone loss around missing teeth sites, requires bone grafting for implant placement.</li>
    <li><strong>Diagnostic Findings:</strong>
        <ul>
            <li>Partial edentulism missing teeth #9, #10, #11</li>
            <li>Periodontal exam: Probing depths 1-4mm, minimal bleeding on probing</li>
            <li>Orthodontic exam: Overbite 6mm, class 2 malocclusion with molar and canine relationship, overjet 7mm, lower crowding 4mm</li>
        </ul>
    </li>
</ul>
<p><br></p>

<p><strong class="ql-size-large">A: Assessment</strong></p>
<p><br></p>
<ul>
    <li><strong>Diagnosis:</strong>
        <ul>
            <li>Partial edentulism due to trauma</li>
            <li>Orthodontic issues: Deep bite, exaggerated curve of Spee, class 2 malocclusion. Bone loss not ready for implant placement.</li>
        </ul>
    </li>
</ul>
<p><br></p>

<p><strong class="ql-size-large">P: Plan</strong></p>
<p><br></p>
<ul>
    <li><strong>Option 1:</strong>
        <ul>
            <li>Step 1: Smoking cessation, periodontal maintenance, bone grafting</li>
            <li>Step 2: Orthodontic treatment (braces for 12-18 months) to level the curve of Spee</li>
            <li>Step 3: Implant placement</li>
        </ul>
    </li>
    <li><strong>Option 2:</strong>
        <ul>
            <li>Step 1: Smoking cessation, periodontal maintenance</li>
            <li>Step 2: Take impression or 3D scan</li>
            <li>Step 3: Deliver partial denture</li>
        </ul>
    </li>
</ul>
<p><br></p>`,
  type: "SOAP",
  recording: "01J5C5FFG6K2PBMW1J0DTFDX6C",
};

const STUB_PATIENT = {
  id: "01J5C5FFG10CEVZB2Z49M3RABX",
  text: `<p><strong class="ql-size-large">Smoking Cessation</strong></p>
<p><br></p>
<p>Smoking cessation is a crucial step for patients considering dental implant placement due to the significant impact smoking has on the success of dental implants. Here's a detailed look at why quitting smoking is important and how it can be approached:</p>
<p><br></p>

<p><strong class="ql-size-large">Why Quit Smoking Before Implant Placement?</strong></p>
<p><br></p>
<ul>
    <li><strong>Increased Risk of Infection:</strong> Smoking impairs the immune system, making it harder for the body to fight infections, which is critical after surgery to prevent complications.</li>
    <li><strong>Impaired Healing:</strong> Nicotine constricts blood vessels, reducing blood flow to the surgical site. This decreased blood flow can delay healing and increase the risk of complications.</li>
    <li><strong>Higher Failure Rate:</strong> Studies have shown that smokers have a higher rate of implant failure than non-smokers. The success rate of dental implants in smokers can be 10-15% lower due to poor osseointegration.</li>
    <li><strong>Periodontal Disease:</strong> Smokers are more prone to developing gum disease, which is a leading cause of implant failure. Healthy gums are essential for the long-term success of dental implants.</li>
</ul>
<p><br></p>

<p><strong class="ql-size-large">Steps to Quit Smoking Before Implant Surgery</strong></p>
<p><br></p>
<ul>
    <li><strong>Set a Quit Date:</strong> Ideally several weeks or months before implant surgery to allow the body time to heal from smoking’s effects.</li>
    <li><strong>Nicotine Replacement Therapy (NRT):</strong> Use nicotine gums, patches, lozenges, or sprays to manage withdrawal symptoms.</li>
    <li><strong>Prescription Medications:</strong> Medications like varenicline (Chantix) or bupropion (Zyban) can reduce nicotine cravings.</li>
    <li><strong>Behavioral Support:</strong> Counseling, either in groups or individually, can increase the chances of success.</li>
    <li><strong>Lifestyle Changes:</strong> Increase physical activity to distract from cravings and reduce stress.</li>
    <li><strong>Avoid Triggers:</strong> Identify and avoid situations that trigger the urge to smoke.</li>
</ul>
<p><br></p>

<p><strong class="ql-size-large">Follow-Up and Monitoring</strong></p>
<p><br></p>
<ul>
    <li><strong>Regular Check-Ins:</strong> Schedule regular visits with your healthcare provider to monitor progress.</li>
    <li><strong>Dental Appointments:</strong> Keep regular appointments with your dentist to monitor gum and bone health.</li>
</ul>
<p><br></p>

<p><strong class="ql-size-large">Bone Grafting</strong></p>
<p><br></p>
<p>Bone grafting is essential for regenerating and rebuilding bone loss due to periodontal disease, trauma, or other conditions. It provides a scaffold for new bone growth to stabilize implants after they are placed.</p>
<p><br></p>

<p><strong class="ql-size-large">Periodontal Maintenance</strong></p>
<p><br></p>
<p>Periodontal maintenance involves regular cleanings and check-ups to manage and prevent the recurrence of periodontal disease. It is crucial for controlling inflammation, preventing disease recurrence, and ensuring long-term stability of previous treatments like bone grafting.</p>
<p><br></p>

<p><strong class="ql-size-large">Implant Procedure</strong></p>
<p><br></p>
<p>During implant placement surgery, the dentist makes an incision in the gum to expose the bone. A hole is drilled, and a titanium implant post is inserted, serving as the new tooth root. The implant undergoes osseointegration, where it fuses with the jawbone over several months to provide a stable base for the artificial tooth. Once osseointegration is complete, a minor surgery is conducted to place the abutment, and impressions are taken to design the final crown, which matches the natural teeth.</p>
<p><br></p>

<p><strong class="ql-size-large">Partial Denture</strong></p>
<p><br></p>
<p>The process of getting partial dentures includes taking impressions of the dental arches to create models for designing dentures. After adjustments, the final dentures are fabricated, and the patient is educated on care and maintenance.</p>
<p><br></p>

<p><strong class="ql-size-large">Orthodontics</strong></p>
<p><br></p>
<p>Orthodontics is required to correct a deep bite before placing dental implants. Proper orthodontic treatment reduces forces on implants, distributes bite forces evenly, and enhances aesthetics and functionality. This ensures a stable environment for the implants and promotes better oral health outcomes.</p>
<p><br></p>`,
  type: "PATIENT",
  recording: "01J5C5EJPK2H7HB1XVMV8DEXD1",
};

function RecordingsViewPage() {
  const params = useParams<{ id: string }>();
  const id = params.id;
  if (!id) throw new Error("No ID provided");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showPinPopup, setShowPinPopup] = useState(false);
  const [recording, setRecording] = useState<Recording | null>(null);
  const [summaries, setSummaries] = useState<SummaryContent>({
    SOAP: "",
    REFERRING_DENTIST: "",
    PATIENT: "",
    EHR: "",
  });
  const [summaryIds, setSummaryIds] = useState<SummaryContent>({
    SOAP: "",
    REFERRING_DENTIST: "",
    PATIENT: "",
    EHR: "",
  });

  const quillModules = {
    toolbar: false, // This disables the toolbar
  };

  const [currentTab, setCurrentTab] = useState<SummaryType>("SOAP");
  const quillRef = useRef<ReactQuill>(null);

  const pinForm = useForm<z.infer<typeof PinSchema>>({
    resolver: zodResolver(PinSchema),
    defaultValues: {
      pin: "",
    },
  });

  useEffect(() => {
    async function fetchRecording() {
      const fetchedRecording = await recordingsApi.getOne(id || "");
      setRecording(fetchedRecording);

      const summariesObj = {
        SOAP: "",
        REFERRING_DENTIST: "",
        PATIENT: "",
        EHR: "",
      };
      const summaryIdsObj = {
        SOAP: "",
        REFERRING_DENTIST: "",
        PATIENT: "",
        EHR: "",
      };

      fetchedRecording.summaries.forEach((summary) => {
        if (
          isSummaryType(summary.type) &&
          summary.type !== "SOAP" &&
          summary.type !== "PATIENT"
        ) {
          summariesObj[summary.type as SummaryType] = summary.text;
          summaryIdsObj[summary.type as SummaryType] = summary.id;
        } else if (summary.type === "SOAP") {
          summariesObj.SOAP = STUB_SOAP.text;
          summaryIdsObj.SOAP = STUB_SOAP.id;
        } else if (summary.type === "PATIENT") {
          summariesObj.PATIENT = STUB_PATIENT.text;
          summaryIdsObj.PATIENT = STUB_PATIENT.id;
        }
      });

      setSummaries(summariesObj);
      setSummaryIds(summaryIdsObj);
      setIsLoading(false);
    }
    fetchRecording();
  }, [id]);

  const onPinSubmit = async (data: z.infer<typeof PinSchema>) => {
    // Simulate API call

    if (data.pin === "1234") {
      console.log("success");
      setShowPinPopup(false);
      pinForm.reset();
      navigate("/recordings/");
    } else {
      pinForm.setError("pin", { type: "manual", message: "Incorrect PIN" });
    }
  };

  const tabItems = [
    { value: "SOAP", label: "SOAP" },
    { value: "PATIENT", label: "Patient" },
  ];

  const handleTabChange = (value: string) => {
    // Check if the value is a valid SummaryType
    if (isSummaryType(value)) {
      setCurrentTab(value);
    } else {
      console.error(`Invalid tab value: ${value}`);
    }
  };

  const handleQuillChange = (content: string) => {
    setSummaries((prev) => ({
      ...prev,
      [currentTab]: content,
    }));
  };

  const updateSummary = async (): Promise<string | undefined> => {
    const summaryId = summaryIds[currentTab];
    const summaryContent = summaries[currentTab];

    if (!summaryId) {
      console.error(`No summary ID found for ${currentTab}`);
      return undefined;
    }

    try {
      setIsLoading(true);
      const updatedSummary = await summariesApi.update(summaryId, {
        text: summaryContent,
      });

      // Optionally, you can update the local state with the response from the API
      setSummaries((prev) => ({
        ...prev,
        [currentTab]: updatedSummary.text,
      }));

      return summaryId;
    } catch (error) {
      console.error("Error updating summary:", error);
      return undefined;
      // Handle the error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewButton = async () => {
    try {
      const summaryId = await updateSummary();
      navigate(`/summaries/${summaryId}/view/`);
    } catch (error) {
      console.error("Error viewing summary:", error);
    }
  };

  const handleEditButton = async () => {
    try {
      const summaryId = await updateSummary();
      navigate(`/summaries/${summaryId}/edit/`);
    } catch (error) {
      console.error("Error updating summary:", error);
    }
  };

  const handleSaveAsPDF = async () => {
    const doc = new jsPDF();

    const editorContainer = document.getElementById("summary-content");

    if (!editorContainer) {
      console.error("No content found to save as PDF");
      return;
    }

    const canvas = await html2canvas(editorContainer);

    const imgData = canvas.toDataURL("image/png");

    const imgWidth = 190;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

    // Generate the PDF as a Blob
    const pdfBlob = doc.output("blob");

    // Suggested file name
    const suggestedName = `Recording_${id}_${currentTab}.pdf`;

    // Check if the browser supports the showSaveFilePicker API
    if ("showSaveFilePicker" in window) {
      try {
        const fileHandle = await (window as any).showSaveFilePicker({
          suggestedName: suggestedName,
          types: [
            {
              description: "PDF File",
              accept: { "application/pdf": [".pdf"] },
            },
          ],
        });
        const writable = await fileHandle.createWritable();
        await writable.write(pdfBlob);
        await writable.close();
      } catch (err) {
        console.error("Failed to save file:", err);
        // Fallback to default download if user cancels or an error occurs
        // defaultSaveFile(pdfBlob, suggestedName);
      }
    } else {
      // Fallback for browsers that don't support showSaveFilePicker
      defaultSaveFile(pdfBlob, suggestedName);
    }
  };

  // Fallback function to save file using traditional method
  const defaultSaveFile = (blob: Blob, fileName: string) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <div className="items-center w-full h-screen overflow-auto p-2 pt-4 sm:pt-8 bg-blue-50">
        <Card className="relative flex flex-col justify-between shadow-md max-w-5xl mx-auto">
          <div
            className="absolute flex justify-center items-center z-10 top-0 right-0 sm:top-2 sm:right-2 h-12 w-12 cursor-pointer"
            onClick={() => setShowPinPopup(true)}
          >
            <X className="h-8 w-8 hover:h-12 hover:w-12 active:w-6 active:h-6 text-slate-500 hover:text-slate-950 active:text-slate-500 transition-all" />
          </div>

          <CardHeader>
            <CardTitle>Select a summary:</CardTitle>
          </CardHeader>

          <CardContent>
            <Tabs
              defaultValue={"SOAP"}
              className="w-full flex flex-col"
              onValueChange={handleTabChange}
            >
              <TabsList>
                {tabItems.map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    className="data-[state=active]:shadow w-full text-sm py-2 px-4"
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
              </TabsList>

              <ReactQuill
                id="summary-content"
                className="flex flex-col mt-4"
                ref={quillRef}
                readOnly={true}
                modules={quillModules}
                value={summaries[currentTab]}
                onChange={(content, delta, source, editor) => {
                  if (source === "user") {
                    handleQuillChange(content);
                  }
                }}
              />

              {/* <div className="flex flex-col h-full mt-4 rounded-md border">
                <div
                  className="p-4"
                  id="summary-content"
                  dangerouslySetInnerHTML={{ __html: summaries[currentTab as SummaryType] }}
                />
              </div> */}
            </Tabs>
          </CardContent>
        </Card>
        <div className="h-52"></div>

        {showPinPopup && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
            <Card className="sm:m-6">
              <CardHeader>
                <CardTitle>Enter PIN</CardTitle>
              </CardHeader>
              <CardContent>
                <Form {...pinForm}>
                  <form
                    onSubmit={pinForm.handleSubmit(onPinSubmit)}
                    className="space-y-4"
                  >
                    <FormField
                      control={pinForm.control}
                      name="pin"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>PIN</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Enter 4-digit PIN"
                              {...field}
                              maxLength={4}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>
              </CardContent>
              <CardFooter className="flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowPinPopup(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={pinForm.handleSubmit(onPinSubmit)}
                >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </div>
        )}
      </div>
      <BottomBar>
        <div className="flex justify-between space-x-2">
          <Button
            className="flex grow shadow-sm"
            variant={"outline"}
            onClick={handleViewButton}
            disabled={isLoading}
          >
            View
          </Button>

          <Button
            className="flex grow shadow-sm"
            variant={"outline"}
            onClick={handleEditButton}
            disabled={isLoading}
          >
            Edit
          </Button>
        </div>

        <Button
          className="shadow-sm"
          type="submit"
          onClick={handleSaveAsPDF}
          disabled={isLoading}
        >
          Export as PDF
        </Button>
      </BottomBar>
    </>
  );
}

export default RecordingsViewPage;
