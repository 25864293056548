import React, { useContext, useState, useEffect } from "react";
import {
  EnvelopeIcon,
  FaceSmileIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { AuthContext } from "@/store/AuthContext";
import { dashboardStatsApi } from "@/services/api";
import Stat from "@/components/Stat";
import { Alert } from "@/components/ui/alert";
import AvatarHeader from "@/components/AvatarHeader";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const EmailDashboard: React.FC = () => {
  const [stats, setStats] = useState({
    emailsOrganized: 0,
    patientsConnected: 0,
    timeSaved: 0,
  });

  const handleStatMenuClick = () => {
    // Handle menu click action
    console.log("Menu clicked");
  };

  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const stats = await dashboardStatsApi.getAllStats();
        setStats(stats);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };

    fetchStats();
  }, []);

  const handleGoToInbox = () => {
    window.open("https://mail.google.com", "_blank");
  };

  return (
    <div className="flex-1 flex flex-col border-y">
      <div className="flex p-6 items-center border-b border-gray-200">
        <p className="text-gray-900 flex-1">
          Welcome, {userDetails?.first_name}!
        </p>
        <AvatarHeader />
      </div>
      <div className="flex-1 p-8 bg-slate-50">
        <Card className="mb-8">
          <CardContent className="pt-6">
            <h2 className="text-xl font-semibold mb-4">
              AI Email Categorization is on
            </h2>
            <Button onClick={handleGoToInbox} className="w-full">
              Go to Gmail
            </Button>
          </CardContent>
        </Card>
        <div className="flex mt-8 mb-8 space-x-8">
          <Stat
            icon={EnvelopeIcon}
            className={"w-1/3"}
            title="Emails organized"
            value={stats.emailsOrganized.toString()}
            percentage={43}
            menuAction={handleStatMenuClick}
          />
          <Stat
            icon={FaceSmileIcon}
            className={"w-1/3"}
            title="Patients connected"
            value={stats.patientsConnected.toString()}
            percentage={-12}
          />
          <Stat
            icon={ClockIcon}
            className={"w-1/3"}
            title="Time saved"
            value={`${stats.timeSaved.toFixed(2)} hours`}
            percentage={18.2}
            menuAction={handleStatMenuClick}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailDashboard;
