import React, { useState, useEffect } from "react";
import api from "../services/customApiInstance";
import { cn } from "@/lib/utils";

interface GoogleAuthButtonProps {
  onSuccess: (data: any) => void;
  onFailure: (error: any) => void;
  forAddEmailAccount?: boolean;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  onSuccess,
  onFailure,
  forAddEmailAccount = false,
}) => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    window.history.replaceState({}, document.title, window.location.pathname);
    if (code && state) {
      handleCallback(code, state);
    }
  }, []);

  const handleLogin = async (): Promise<void> => {
    try {
      setIsAuthenticating(true);
      const endpoint = forAddEmailAccount
        ? "/email/oauth/gmail/add/"
        : "/users/oauth/gmail/login/";
      const response = await api.get(endpoint);
      window.location.href = response.data.auth_url;
    } catch (error) {
      console.error("Error initiating Google login:", error);
      onFailure(error);
      setIsAuthenticating(false);
    }
  };

  const handleCallback = async (code: string, state: string): Promise<void> => {
    try {
      setIsAuthenticating(true);
      const response = await api.post("/users/oauth/gmail/callback/", {
        code,
        state,
      });
      console.log("response", response.data);
      onSuccess(response.data);
    } catch (error) {
      console.error("Error handling Google callback:", error);
      onFailure(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <button
      onClick={handleLogin}
      disabled={isAuthenticating}
      className={cn(
        "flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-pointer",
        "focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500",
        "transition-colors duration-200",
        "bg-[#016BFF] hover:bg-[#0056D6]"
      )}
    >
      <span className="flex items-center justify-start gap-4 w-[210px]">
        <svg
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_34_5442)">
            <path
              d="M36 0.5H4C1.79086 0.5 0 2.29086 0 4.5V36.5C0 38.7091 1.79086 40.5 4 40.5H36C38.2091 40.5 40 38.7091 40 36.5V4.5C40 2.29086 38.2091 0.5 36 0.5Z"
              fill="white"
            />
            <mask
              id="mask0_34_5442"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="8"
              y="8"
              width="24"
              height="25"
            >
              <path d="M8 8.5H32V32.5H8V8.5Z" fill="white" />
            </mask>
            <g mask="url(#mask0_34_5442)">
              <path
                d="M31.7662 20.776C31.7662 19.961 31.7002 19.141 31.5592 18.338H20.2402V22.959H26.7222C26.5894 23.6974 26.3084 24.4013 25.8961 25.0281C25.4837 25.6549 24.9487 26.1917 24.3232 26.606V29.604H28.1902C30.4612 27.514 31.7662 24.427 31.7662 20.776Z"
                fill="#4285F4"
              />
              <path
                d="M20.2401 32.5C23.4771 32.5 26.2061 31.438 28.1951 29.604L24.3281 26.6061C23.2521 27.3371 21.8631 27.7521 20.2441 27.7521C17.1141 27.7521 14.4601 25.64 13.5071 22.8H9.51709V25.8911C10.5164 27.8783 12.0482 29.5487 13.9417 30.7159C15.8352 31.8831 18.0158 32.5001 20.2401 32.5Z"
                fill="#34A853"
              />
              <path
                d="M13.5032 22.8001C13.0005 21.31 13.0005 19.6961 13.5032 18.2061V15.1151H9.51715C8.67782 16.7871 8.24072 18.6321 8.24072 20.5031C8.24072 22.374 8.67782 24.219 9.51715 25.8911L13.5032 22.8011V22.8001Z"
                fill="#FBBC04"
              />
              <path
                d="M20.2401 13.25C21.951 13.2224 23.6043 13.8686 24.8431 15.049L28.2701 11.623C26.0974 9.58375 23.2197 8.46457 20.2401 8.50002C18.0153 8.49987 15.8342 9.11833 13.9406 10.2863C12.047 11.4543 10.5155 13.1258 9.51709 15.114L13.5031 18.204C14.4511 15.361 17.1101 13.249 20.2401 13.249V13.25Z"
                fill="#EA4335"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_34_5442">
              <rect width="40" height="41" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span className="text-sm font-semibold leading-6">
          {isAuthenticating ? "Authenticating..." : "Sign in with Google"}
        </span>
      </span>
    </button>
  );
};

export default GoogleAuthButton;
