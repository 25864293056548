import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Mic, Pause, Play, Square } from "lucide-react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo.svg";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";

const RecordingPage: React.FC = () => {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(true);

  return (
    <div className="bg-gray-50 flex flex-col items-center justify-center min-h-screen min-w-full">
      <div className="flex mb-10">
        <img className="h-8 w-auto" src={logo} alt="DentoAI" />
      </div>
      <Card className="w-full max-w-xl p-6">
        <CardHeader>
          <CardTitle className="text-center mt-2 mb-4 flex justify-center">
            {isRecording ? (
              <div className="flex flex-row gap-4">
                Recording in process
                <Mic className="w-5 h-5 mr-2 animate-spin" />
              </div>
            ) : (
              "Recording paused"
            )}
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          <div className="flex justify-around items-center h-full w-full">
            <Button
              variant="outline"
              size="icon"
              className="group w-24 h-24 rounded-full border-4 hover:border-4 active:border-8 border-slate-950 bg-red-500 hover:bg-red-400 active:bg-red-600 drop-shadow hover:drop-shadow-md active:drop-shadow-sm transition-all"
              onClick={() => {
                // Implement recording logic here
                setIsRecording(!isRecording);
              }}
            >
              {isRecording && (
                <Pause className="h-12 w-12 group-hover:h-14 group-hover:w-14 group-active:h-10 group-active:w-10 text-slate-950 group-hover:text-slate-100 group-active:text-slate-950 transition-all" />
              )}
              {!isRecording && (
                <Play className="h-12 w-12 group-hover:h-14 group-hover:w-14 group-active:h-10 group-active:w-10 text-slate-950 group-hover:text-slate-100 group-active:text-slate-950 transition-all" />
              )}
            </Button>

            <Button
              variant="outline"
              size="icon"
              className="group w-24 h-24 rounded-full border-4 hover:border-4 active:border-8 border-slate-950 bg-red-500 hover:bg-red-400 active:bg-red-600 drop-shadow hover:drop-shadow-md active:drop-shadow-sm transition-all"
              onClick={() => {
                // Implement recording logic here
                navigate("/recordings/patient-summary"); // FIXME: Replace this with Sinchan's recording screen
              }}
            >
              <Square className="h-12 w-12 group-hover:h-14 group-hover:w-14 group-active:h-10 group-active:w-10 text-slate-950 group-hover:text-slate-100 group-active:text-slate-950 transition-all" />
            </Button>
          </div>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
};

export default RecordingPage;
