import React, { useContext, useState, useEffect } from "react";
import { Trash2, Plus, Mail } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent } from "@/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import ColorSwatchPicker from "./ColorSwatchPicker";
import { AuthContext } from "../store/AuthContext";
import { EmailExample, EmailType } from "@/services/api";
import { InputWithInsetLabel } from "./ui/inputWithInsetLabel";
import EmailPickerDialog from "./EmailPickerDialog";
import EmailList from "./EmailList";
import { Textarea } from "./ui/textarea";

interface EmailTypeFormProps {
  emailType?: EmailType;
  onSubmit: (data: Omit<EmailType, "id">) => void;
}

const EmailTypeForm: React.FC<EmailTypeFormProps> = ({
  emailType,
  onSubmit,
}) => {
  const [title, setTitle] = useState(emailType?.title || "");
  const [color, setColor] = useState(emailType?.color || "#000000");
  const [instructions, setInstructions] = useState(
    emailType?.instructions || ""
  );
  const [examples, setExamples] = useState<EmailExample[]>(
    emailType?.examples || []
  );
  const [isEmailPickerOpen, setIsEmailPickerOpen] = useState(false);
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    if (emailType) {
      setTitle(emailType.title);
      setColor(emailType.color);
      setInstructions(emailType.instructions || "");
      setExamples(emailType.examples?.length > 0 ? emailType.examples : []);
    }
  }, [emailType]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("userDetails", userDetails);
    const orgId = userDetails?.organization?.id;
    if (!orgId) {
      console.error("Organization ID not available");
      return;
    }
    onSubmit({
      organization: orgId,
      title,
      color,
      instructions,
      examples,
    });
  };

  const addExample = () => {
    setIsEmailPickerOpen(true);
  };

  const handleAddEmails = (emails: EmailExample[]) => {
    setExamples([...examples, ...emails]);
    setIsEmailPickerOpen(false);
  };

  const removeExample = (index: number) => {
    setExamples(examples.filter((_, i) => i !== index));
  };

  const updateExample = (
    index: number,
    field: keyof EmailExample,
    value: string
  ) => {
    const newExamples = [...examples];
    newExamples[index] = { ...newExamples[index], [field]: value };
    setExamples(newExamples);
  };

  return (
    <Card className="bg-slate-50">
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2 pt-5">
            <Label htmlFor="title">Name</Label>
            <Input
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Category Name"
            />
          </div>

          <Accordion type="single" collapsible className="">
            <AccordionItem
              value="color-picker"
              className="border rounded-md shadow-sm bg-white px-3"
            >
              <AccordionTrigger className="hover:no-underline">
                <div className="flex justify-between items-center w-full">
                  <Label className="cursor-pointer">Color</Label>
                  <div
                    className="w-6 h-6 rounded-full shadow-md"
                    style={{ backgroundColor: color }}
                  ></div>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <ColorSwatchPicker color={color} onChange={setColor} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <div className="space-y-2">
            <Label htmlFor="instructions">Instructions</Label>
            <Textarea
              id="instructions"
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              placeholder="Enter instructions for this email type"
              className="w-full min-h-[100px] p-2 border rounded"
            />
          </div>

          <Button type="submit" className="w-full">
            {emailType ? "Save Changes" : "Create Category"}
          </Button>
        </form>
      </CardContent>

      <EmailPickerDialog
        isOpen={isEmailPickerOpen}
        onClose={() => setIsEmailPickerOpen(false)}
        onAddEmails={handleAddEmails}
        title="Add Example Emails"
      />
    </Card>
  );
};

export default EmailTypeForm;
