import * as React from "react";
import { cn } from "@/lib/utils";

export interface TextareaWithInsetLabelProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  textareaClassName?: string;
}

const TextareaWithInsetLabel = React.forwardRef<
  HTMLTextAreaElement,
  TextareaWithInsetLabelProps
>(
  (
    {
      className,
      label,
      containerClassName,
      labelClassName,
      textareaClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          "rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-cyan-600 bg-white",
          containerClassName
        )}
      >
        {label && (
          <label
            htmlFor={props.id || props.name}
            className={cn(
              "block text-xs font-medium text-gray-900",
              labelClassName
            )}
          >
            {label}
          </label>
        )}
        <textarea
          className={cn(
            "flex min-h-[80px] w-full rounded-md border-none bg-background -m-2 py-2 text-sm focus-within:ring-0 placeholder:text-gray-400 disabled:cursor-not-allowed disabled:opacity-50",
            "bg-transparent",
            "min-h-[80px] resize-none",
            textareaClassName,
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
TextareaWithInsetLabel.displayName = "TextareaWithInsetLabel";

export { TextareaWithInsetLabel };
