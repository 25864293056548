import React, { useEffect, useState } from "react";
import { Plus, Check } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { RecentEmail, recentEmailsApi } from "@/services/api";
import EmailList from "./EmailList";
import EmailLoading from "./EmailLoading";

interface EmailPickerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAddEmails: (emails: RecentEmail[]) => void;
  title?: string;
}

export const EmailPickerDialog: React.FC<EmailPickerDialogProps> = ({
  isOpen,
  onClose,
  onAddEmails,
  title = "Select Emails",
}) => {
  const [emails, setEmails] = useState<RecentEmail[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<Set<number>>(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEmails = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const result = await recentEmailsApi.getRecentEmails();
        if (Array.isArray(result)) {
          setEmails(result);
        } else {
          // If result is { message: string }, treat it as an error
          setError(result.message);
        }
      } catch (err) {
        setError("An error occurred while fetching emails");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchEmails();
      setSelectedEmails(new Set());
    }
  }, [isOpen]);

  const handleToggleEmail = (index: number) => {
    setSelectedEmails((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const handleAddSelectedEmails = () => {
    const selectedEmailsList = Array.from(selectedEmails).map(
      (index) => emails[index]
    );
    onAddEmails(selectedEmailsList);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl w-full h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="flex-1 overflow-auto pr-4">
          {isLoading && (
            <EmailLoading loadingMessage="Loading emails..." hideLogo={true} />
          )}
          {error && <p className="text-red-500">{error}</p>}
          {!isLoading && !error && (
            <EmailList
              emails={emails}
              onUpdateEmail={() => {}}
              actionIcon={(props) =>
                selectedEmails.has(props.index) ? (
                  <Check {...props} />
                ) : (
                  <Plus {...props} />
                )
              }
              onActionClick={handleToggleEmail}
              actionButtonClassName={(index) =>
                selectedEmails.has(index)
                  ? "bg-green-100 hover:bg-green-200"
                  : "bg-green-50 hover:bg-green-100"
              }
              actionButtonIconClassName={(index) => "text-green-600"}
              emailNotEditable={true}
            />
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={handleAddSelectedEmails}
            className="bg-green-500 hover:bg-green-600 text-white"
            disabled={selectedEmails.size === 0}
          >
            Add Examples ({selectedEmails.size})
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EmailPickerDialog;
