import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Plus, ChevronRight, Trash2, AlertTriangle } from "lucide-react";
import EmailTypeForm from "../../components/EmailTypeForm";
import { generateColorPairings } from "../../lib/colorContrastUtils";
import { useEmailTypeStore } from "../../store/useEmailTypeStore";
import { emailTypeApi, EmailType } from "../../services/api";

const EmailTypesPage: React.FC = () => {
  const {
    emailTypes,
    setEmailTypes,
    addEmailType,
    updateEmailType,
    deleteEmailType,
  } = useEmailTypeStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEmailType, setSelectedEmailType] = useState<EmailType | null>(
    null
  );
  const colorPairings = generateColorPairings();

  useEffect(() => {
    const fetchEmailTypes = async () => {
      try {
        const fetchedEmailTypes = await emailTypeApi.getAll();
        setEmailTypes(fetchedEmailTypes);
      } catch (error) {
        console.error("Failed to fetch email types:", error);
      }
    };
    fetchEmailTypes();
  }, [setEmailTypes]);

  const handleDelete = async (id: number) => {
    try {
      await emailTypeApi.delete(id);
      deleteEmailType(id);
      setSelectedEmailType(null);
    } catch (error) {
      console.error("Failed to delete email type:", error);
    }
  };

  const handleUpdate = async (id: number, updatedData: Partial<EmailType>) => {
    try {
      const updatedEmailType = await emailTypeApi.update(id, updatedData);
      updateEmailType(id, updatedEmailType);
      setSelectedEmailType(updatedEmailType);
    } catch (error) {
      console.error("Failed to update email type:", error);
    }
  };

  const handleCreate = async (newEmailType: Omit<EmailType, "id">) => {
    try {
      const createdEmailType = await emailTypeApi.create(newEmailType);
      addEmailType(createdEmailType);
      setIsDialogOpen(false);
      setSelectedEmailType(createdEmailType);
    } catch (error) {
      console.error("Failed to create email type:", error);
    }
  };

  const getTextColor = (backgroundColor: string) => {
    const pairing = colorPairings.find((p) => p.background === backgroundColor);
    return pairing ? pairing.text : "#000000";
  };

  return (
    <div className="flex-1 bg-slate-50 p-8 overflow-hidden border-y">
      <div className="flex flex-col h-full border border-slate-200 bg-white rounded-lg overflow-hidden">
        <div className="border-b border-slate-200 px-4 py-5 sm:px-6">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-900">Categories</h3>
            <Button onClick={() => setIsDialogOpen(true)}>
              <Plus className="mr-2 h-4 w-4" />
              Create Categories
            </Button>
          </div>
        </div>
        <div className="flex flex-1 min-h-0 overflow-hidden">
          {emailTypes.length === 0 ? (
            <div className="p-8 w-full">
              <Alert className="bg-slate-50">
                <AlertTriangle className="h-4 w-4" />
                <AlertTitle>No categories found</AlertTitle>
                <AlertDescription>
                  Click the "Create Category" button to add your first category.
                </AlertDescription>
              </Alert>
            </div>
          ) : (
            <>
              <div className="border-r border-slate-200 overflow-y-auto flex-shrink-0 w-64">
                {emailTypes.map((emailType) => (
                  <div
                    key={emailType.id}
                    className={`flex items-center justify-between px-4 py-4 hover:bg-slate-50 cursor-pointer ${
                      selectedEmailType?.id === emailType.id
                        ? "bg-slate-100"
                        : ""
                    }`}
                    onClick={() => setSelectedEmailType(emailType)}
                  >
                    <div className="flex items-center flex-1 min-w-0">
                      <span
                        className="px-2 py-1 text-sm font-semibold rounded-lg truncate"
                        style={{
                          backgroundColor: emailType.color,
                          color: getTextColor(emailType.color),
                        }}
                      >
                        {emailType.title}
                      </span>
                    </div>
                    <ChevronRight className="h-4 w-4 text-slate-400 flex-shrink-0 ml-2" />
                  </div>
                ))}
              </div>
              <div className="flex-1 p-4 overflow-auto min-w-0">
                {selectedEmailType ? (
                  <div className="space-y-4 max-w-full">
                    <div className="flex justify-between items-center">
                      <h4 className="text-lg font-semibold truncate">
                        {selectedEmailType.title}
                      </h4>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() =>
                          selectedEmailType.id &&
                          handleDelete(selectedEmailType.id)
                        }
                      >
                        <Trash2 className="h-4 w-4 mr-2" />
                        Delete
                      </Button>
                    </div>
                    <div className="overflow-x-hidden">
                      <EmailTypeForm
                        emailType={selectedEmailType}
                        onSubmit={(data) =>
                          selectedEmailType.id &&
                          handleUpdate(selectedEmailType.id, data)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full text-slate-500">
                    Select a category to view or edit its details
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="sm:max-w-[600px] max-h-[90vh] flex flex-col">
            <DialogHeader>
              <DialogTitle>Create New Category</DialogTitle>
            </DialogHeader>
            <div className="flex-1 overflow-y-auto">
              <EmailTypeForm onSubmit={handleCreate} />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default EmailTypesPage;
