import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "../store/AuthContext";
import EmailTypesPage from "../pages/email/EmailTypesPage";
import EmailAccountsPage from "../pages/email/EmailAccountsPage";
import EmailDashboard from "@/pages/email/EmailDashboardPage";
import EmailOnboardingPage from "@/pages/email/EmailOnboardingPage";

const EmailRouter: React.FC = () => {
  const { userDetails } = useContext(AuthContext);

  if (!userDetails) {
    return <Navigate to="/login" />;
  }

  if (
    !userDetails.onboarding_completed &&
    window.location.pathname !== "/email/onboarding"
  ) {
    return <Navigate to="/email/onboarding" />;
  }

  return (
    <Routes>
      <Route path="/" element={<EmailDashboard />} />
      <Route path="/types" element={<EmailTypesPage />} />
      <Route path="/accounts" element={<EmailAccountsPage />} />
      <Route path="/onboarding" element={<EmailOnboardingPage />} />
    </Routes>
  );
};

export default EmailRouter;
