import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailLoading from "@/components/EmailLoading";
import InboxPreview from "@/components/InboxPreview";
import EmailOnboardingCustomize from "@/components/EmailTypesOnboarding";
import {
  BackgroundJob,
  backgroundJobApi,
  recentEmailsApi,
} from "@/services/api";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { AuthContext } from "@/store/AuthContext";
import EmailOnboardingStart from "./EmailOnboardingStart";
import logo from "@/assets/images/Logo.svg";
import { Steps } from "@/components/ui/steps";
import { Grid } from "lucide-react";

enum OnboardingState {
  LOADING = "LOADING",
  START = "START",
  INBOX_PREVIEW = "INBOX_PREVIEW",
  EMAIL_TYPES = "EMAIL_TYPES",
  APPLYING_CATEGORIES = "APPLYING_CATEGORIES",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

const MAX_RETRIES = 30;

type StepStatus = "complete" | "current" | "upcoming";

interface Step {
  name: string;
  status: StepStatus;
}

const EmailOnboardingPage: React.FC = () => {
  const [onboardingState, setOnboardingState] = useState<OnboardingState>(
    OnboardingState.START
  );
  const [backgroundJob, setBackgroundJob] = useState<BackgroundJob | null>(
    null
  );
  const navigate = useNavigate();
  const { fetchUserData, logout } = useContext(AuthContext);

  const initialSteps: Step[] = [
    { name: "Get Started", status: "upcoming" },
    { name: "Preview Inbox", status: "upcoming" },
    { name: "Customize", status: "upcoming" },
  ];

  const [steps, setSteps] = useState<Step[]>(initialSteps);

  useEffect(() => {
    // Update steps based on onboarding state
    const updatedSteps: Step[] = initialSteps.map((step, index) => {
      let newStatus: StepStatus = step.status;
      if (index === 0 && onboardingState === OnboardingState.START) {
        newStatus = "current";
      } else if (
        index === 1 &&
        onboardingState === OnboardingState.INBOX_PREVIEW
      ) {
        newStatus = "current";
      } else if (
        index === 2 &&
        onboardingState === OnboardingState.EMAIL_TYPES
      ) {
        newStatus = "current";
      } else if (index < getStepIndex(onboardingState)) {
        newStatus = "complete";
      }
      return { ...step, status: newStatus };
    });
    setSteps(updatedSteps);
  }, [onboardingState]);

  const getStepIndex = (state: OnboardingState) => {
    switch (state) {
      case OnboardingState.START:
        return 0;
      case OnboardingState.INBOX_PREVIEW:
        return 1;
      case OnboardingState.EMAIL_TYPES:
      case OnboardingState.APPLYING_CATEGORIES:
        return 2;
      case OnboardingState.COMPLETED:
        return 3;
      default:
        return -1;
    }
  };

  const handleStartComplete = () => {
    setOnboardingState(OnboardingState.INBOX_PREVIEW);
  };

  const handleInboxPreviewComplete = () => {
    setOnboardingState(OnboardingState.EMAIL_TYPES);
  };

  const handleEmailTypesCustomizeComplete = async () => {
    try {
      await recentEmailsApi.applyPreview();
      openGmailInNewTab();
      await fetchUserData();
      navigate("/email/");
    } catch (error) {
      console.error("Error applying preview:", error);
      setOnboardingState(OnboardingState.ERROR);
    }
  };

  const openGmailInNewTab = () => {
    window.open("https://mail.google.com", "_blank");
  };

  const handleBack = () => {
    if (onboardingState === OnboardingState.INBOX_PREVIEW) {
      setOnboardingState(OnboardingState.START);
    } else if (onboardingState === OnboardingState.EMAIL_TYPES) {
      setOnboardingState(OnboardingState.INBOX_PREVIEW);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const renderOnboardingStep = () => {
    switch (onboardingState) {
      case OnboardingState.LOADING:
        return <EmailLoading />;
      case OnboardingState.START:
        return <EmailOnboardingStart onNext={handleStartComplete} />;
      case OnboardingState.INBOX_PREVIEW:
        return (
          <InboxPreview
            onBack={handleBack}
            onNext={handleInboxPreviewComplete}
          />
        );
      case OnboardingState.EMAIL_TYPES:
        return (
          <EmailOnboardingCustomize
            onComplete={handleEmailTypesCustomizeComplete}
            onPreviewAgain={handleBack}
          />
        );
      case OnboardingState.APPLYING_CATEGORIES:
        return (
          <EmailLoading loadingMessage="Applying categories to inbox..." />
        );
      case OnboardingState.COMPLETED:
        return <div>Onboarding Completed!</div>;
      case OnboardingState.ERROR:
        return (
          <Alert variant="destructive">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              An error occurred during onboarding. Please contact DentoAI
              support for assistance.
            </AlertDescription>
          </Alert>
        );
      default:
        return <div>Unknown State</div>;
    }
  };

  return (
    <div className="container mx-auto p-4 flex flex-col min-h-screen">
      <div className="flex items-center justify-between mt-6 mb-12">
        <div className="flex items-center">
          <img className="h-8 w-auto mr-5" src={logo} alt="DentoAI" />
          <span className="text-2xl font-light border-l-2 border-gray-950 pl-5">
            Email AI Copilot
          </span>
        </div>
        <div className="flex gap-1">
          <Button
            variant="outline"
            onClick={() => navigate("/apps")}
            className="mr-4"
          >
            <Grid className="mr-2 h-4 w-4" />
            Back to Apps
          </Button>
          <Button variant="outline" onClick={handleLogout} className="">
            Logout
          </Button>
        </div>
      </div>
      <Steps steps={steps} className="mb-10" />
      <div className="flex-grow">{renderOnboardingStep()}</div>
    </div>
  );
};

export default EmailOnboardingPage;
