import React, { useState, useContext, FormEvent, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../store/AuthContext";
import { UserDetails, updateLoggedInUser } from "../services/authUtils";
import GoogleAuthButton from "../components/GoogleAuthButton";
import MicrosoftAuthButton from "@/components/MicrosoftAuthButton";
import api from "../services/customApiInstance";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import logo from "../assets/images/Logo.svg";

interface LoginResponse {
  user: UserDetails;
  token: string;
  email_account?: object;
}

function LoginPage() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showResend, setShowResend] = useState<boolean>(false);
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { updateAuthStatus } = useContext(AuthContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    if (code && state) {
      setIsLoading(true);
      // The actual OAuth callback handling is done in GoogleAuthButton
      // We just need to show loading state here
    }
  }, [location]);

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShowResend(false);
  };

  const handleSuccessfulLogin = (data: LoginResponse) => {
    console.log("Login response:", data);
    if (data.email_account) {
      navigate("/accounts");
      return;
    }

    updateAuthStatus(data.user, data.token);
    updateLoggedInUser(data.user);
    navigate("/apps");
  };

  const handleSubmitEmailLogin = async (e: FormEvent) => {
    e.preventDefault();
    clearMessages();
    setIsLoading(true);

    try {
      const response = await api.post(
        "/users/login/",
        { email, password },
        { authRequired: false }
      );
      if (response.status === 200) {
        handleSuccessfulLogin(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.data.code === "EMAIL_NOT_VERIFIED") {
        setErrorMessage(
          "Your email address has not been verified. Please check your email for the verification link."
        );
        setShowResend(true);
      } else if (
        error.response &&
        error.response.data.code === "GOOGLE_SIGNUP"
      ) {
        setErrorMessage(
          "You've signed up with Google. Please use Google to login."
        );
      } else {
        setErrorMessage("Invalid email or password");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerificationEmail = async () => {
    try {
      clearMessages();
      setIsLoading(true);
      await api.post("/users/resend-verification/", { email });
      setSuccessMessage(
        "Verification email resent. Please check your email to verify."
      );
      setShowResend(false);
    } catch (error) {
      setErrorMessage(
        "Failed to resend verification email. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthSuccess = (data: LoginResponse) => {
    setIsLoading(false);
    handleSuccessfulLogin(data);
  };

  const handleAuthFailure = (error: any) => {
    setIsLoading(false);
    setErrorMessage("Authentication failed. Please try again.");
  };

  if (isLoading) {
    return (
      <div className="bg-gray-50 flex flex-col items-center justify-center min-h-screen min-w-full">
        <div className="flex mb-10">
          <img className="h-8 w-auto" src={logo} alt="DentoAI" />
        </div>
        <Card className="w-full max-w-xl p-6">
          <CardContent className="flex items-center justify-center">
            <div className="text-center flex flex-col items-center">
              <div className="animate-spin rounded-full h-10 w-10 border-b-4 border-gray-900"></div>
              <p className="mt-6 text-xl font-semibold">Loading...</p>
              <p className="mt-2 text-gray-500">
                Just finish up the authentication process.
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 flex flex-col items-center justify-center min-h-screen min-w-full">
      <div className="flex mb-10">
        <img className="h-8 w-auto" src={logo} alt="DentoAI" />
      </div>
      <Card className="w-full max-w-xl p-6">
        <CardHeader>
          <CardTitle className="text-center mt-2 mb-4">
            Sign in to your account
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          {successMessage && (
            <Alert className="mb-4" variant="default">
              <AlertDescription>{successMessage}</AlertDescription>
            </Alert>
          )}
          {errorMessage && (
            <Alert className="mb-4" variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          {showResend && (
            <Alert className="mb-4" variant="default">
              <AlertDescription>
                If you did not receive the email, click{" "}
                <span
                  onClick={handleResendVerificationEmail}
                  className="text-primary cursor-pointer hover:underline"
                >
                  here
                </span>{" "}
                to resend.
              </AlertDescription>
            </Alert>
          )}

          <div className="flex items-center justify-center space-x-4">
            <GoogleAuthButton
              onSuccess={handleAuthSuccess}
              onFailure={handleAuthFailure}
            />
            {/* <MicrosoftAuthButton
              onSuccess={handleAuthSuccess}
              onFailure={handleAuthFailure}
            /> */}
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                Or
              </span>
            </div>
          </div>

          {!showEmailForm ? (
            <></>
          ) : (
            // <Button
            //   variant="outline"
            //   className="w-full"
            //   onClick={() => setShowEmailForm(true)}
            // >
            //   Sign in with email
            // </Button>
            <form onSubmit={handleSubmitEmailLogin} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email address</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <Label htmlFor="password">Password</Label>
                  <Link
                    to="/forgot-password"
                    className="text-sm text-primary hover:underline"
                  >
                    Forgot password?
                  </Link>
                </div>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" className="w-full">
                Sign in
              </Button>
            </form>
          )}
        </CardContent>
        <CardFooter>
          <p className="text-sm text-center text-muted-foreground w-full">
            Don't have an account yet?{" "}
            <Link to="/register" className="text-primary hover:underline">
              Sign Up
            </Link>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}

export default LoginPage;
