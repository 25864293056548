import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./Layout";
import AboutUsPage from "./pages/AboutUsPage";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import Terms from "./pages/TermsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import RegistrationPage from "./pages/RegistrationPage";
import NoPage from "./pages/NoPage";
import { AuthProvider } from "./store/AuthContext";
import AppsPage from "./pages/AppsPage";
import EmailRouter from "./routes/EmailRouter";
import RecordingRouter from "./routes/RecordingRouter";
import SummaryRouter from "./routes/SummaryRouter";

function App(): JSX.Element {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<AppsPage />} />
            <Route path="apps" element={<AppsPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<Terms />} />
            <Route
              path="reset-password/:uidb64/:token"
              element={<ResetPasswordPage />}
            />
            <Route
              path="verify-email/:uid/:token"
              element={<EmailVerificationPage />}
            />
            <Route path="register" element={<RegistrationPage />} />
            <Route path="about-us" element={<AboutUsPage />} />

            <Route path="email/*" element={<EmailRouter />} />
            <Route path="recordings/*" element={<RecordingRouter />} />
            <Route path="summaries/*" element={<SummaryRouter />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
