import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "../store/AuthContext";
import RecordingListPage from "../pages/recording/RecordingListPage";
import RecordingCreatePage from "../pages/recording/RecordingCreatePage";
import RecordingViewPage from "../pages/recording/RecordingViewPage";
import RecordingStartPage from "../pages/recording/RecordingStartPage";
import RecordingPage from "../pages/recording/RecordingPage";
import RecordingNameFormPage from "../pages/recording/RecordingNameFormPage";
import RecordingPatientSummaryPage from "../pages/recording/RecordingPatientSummaryPage";

const RecordingRouter: React.FC = () => {
  const { userDetails } = useContext(AuthContext);

  if (!userDetails) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route path="/" element={<RecordingListPage />} />
      <Route path="/new" element={<RecordingCreatePage />} />
      <Route path="/view/:id" element={<RecordingViewPage />} />
      <Route path="/start-recording" element={<RecordingStartPage />} />
      <Route path="/recording" element={<RecordingPage />} />
      <Route path="/name-form" element={<RecordingNameFormPage />} />
      <Route
        path="/patient-summary"
        element={<RecordingPatientSummaryPage />}
      />
    </Routes>
  );
};

export default RecordingRouter;
