import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import api from "../../services/customApiInstance";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import logo from "../../assets/images/Logo.svg";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { useForm, ControllerRenderProps, FieldValues } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
});

type FormValues = z.infer<typeof formSchema>;

function RecordingNameFormPage() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { updateAuthStatus } = useContext(AuthContext);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      //FIXME: uncomment code and add actual post request
      // const response = await api.post("/users/update-name/", values);
      // if (response.status === 200) {
      //   setSuccessMessage("Name updated successfully");
      //   navigate("/")
      // }
      setSuccessMessage("Name updated successfully");
      navigate("/recordings/start-recording");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message || "An error occurred");
      } else {
        setErrorMessage("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 flex flex-col items-center justify-center min-h-screen min-w-full">
      <div className="flex mb-10">
        <img className="h-8 w-auto" src={logo} alt="DentoAI" />
      </div>
      <Card className="w-full max-w-xl p-6">
        <CardHeader>
          <CardTitle className="text-center mt-2 mb-4">
            Register to continue
          </CardTitle>
        </CardHeader>
        <CardContent>
          {successMessage && (
            <Alert className="mb-4" variant="default">
              <AlertDescription>{successMessage}</AlertDescription>
            </Alert>
          )}
          {errorMessage && (
            <Alert className="mb-4" variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="firstName"
                render={({
                  field,
                }: {
                  field: ControllerRenderProps<FormValues, "firstName">;
                }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your first name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({
                  field,
                }: {
                  field: ControllerRenderProps<FormValues, "lastName">;
                }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your last name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}

export default RecordingNameFormPage;
