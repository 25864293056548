import React, { useState, useEffect } from "react";
import api from "../services/customApiInstance";
import { cn } from "@/lib/utils";

interface MicrosoftAuthButtonProps {
  onSuccess: (data: any) => void;
  onFailure: (error: any) => void;
}

const MicrosoftAuthButton: React.FC<MicrosoftAuthButtonProps> = ({
  onSuccess,
  onFailure,
}) => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    window.history.replaceState({}, document.title, window.location.pathname);
    if (code && state) {
      handleCallback(code, state);
    }
  }, []);

  const handleLogin = async (): Promise<void> => {
    // try {
    //   setIsAuthenticating(true);
    //   const response = await api.get("/users/oauth/gmail/login/");
    //   window.location.href = response.data.auth_url;
    // } catch (error) {
    //   console.error("Error initiating Google login:", error);
    //   onFailure(error);
    //   setIsAuthenticating(false);
    // }
  };

  const handleCallback = async (code: string, state: string): Promise<void> => {
    // try {
    //   setIsAuthenticating(true);
    //   const response = await api.post("/users/oauth/gmail/callback/", {
    //     code,
    //     state,
    //   });
    //   onSuccess(response.data);
    // } catch (error) {
    //   console.error("Error handling Google callback:", error);
    //   onFailure(error);
    // } finally {
    //   setIsAuthenticating(false);
    // }
  };

  return (
    <button
      onClick={handleLogin}
      disabled={isAuthenticating}
      className={cn(
        "flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm",
        "focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500",
        "transition-colors duration-200",
        "bg-[#0B3558] hover:bg-[#164367]"
      )}
    >
      <span className="flex items-center justify-start gap-4 w-[210px]">
        <svg
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_34_5454)">
            <path
              d="M36 0.5H4C1.79086 0.5 0 2.29086 0 4.5V36.5C0 38.7091 1.79086 40.5 4 40.5H36C38.2091 40.5 40 38.7091 40 36.5V4.5C40 2.29086 38.2091 0.5 36 0.5Z"
              fill="white"
            />
            <path
              d="M21.0229 21.523H31.2499V31.75H21.0229V21.523Z"
              fill="#FEBA08"
            />
            <path d="M8.75 21.523H18.977V31.75H8.75V21.523Z" fill="#05A6F0" />
            <path
              d="M21.0229 9.25H31.2499V19.477H21.0229V9.25Z"
              fill="#80BC06"
            />
            <path d="M8.75 9.25H18.977V19.477H8.75V9.25Z" fill="#F25325" />
          </g>
          <defs>
            <clipPath id="clip0_34_5454">
              <rect width="40" height="41" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span className="text-sm font-semibold leading-6">
          {isAuthenticating ? "Authenticating..." : "Sign in with Microsoft"}
        </span>
      </span>
    </button>
  );
};

export default MicrosoftAuthButton;
