import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import logo from "../../assets/images/Logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { summariesApi } from "@/services/api";
import { Skeleton } from "@/components/ui/skeleton"
import ReactQuill from "react-quill";
import { Button } from "@/components/ui/button";
import BottomBar from "@/components/BottomBar";

const SummaryEditPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const id = params.id;
  if (!id) throw new Error("No ID provided");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [summaryType, setSummaryType] = useState<string>("");
  const [summaryContent, setSummaryContent] = useState<string>("");
  const [summaryTitle, setSummaryTitle] = useState<string>("a");
  const quillRef = useRef<ReactQuill>(null);

  const toolbarOptions = [
    [
      'bold',
      'italic',
      'underline',
      // 'strike'
    ],        // toggled buttons
    [
      'blockquote',
      // 'code-block'
    ],
    [
      'link',
      'image',
      'video',
      // 'formula'
    ],
  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],           // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean'],                                         // remove formatting button
  
    ['undo', 'redo']                                   // history buttons (note: custom modules may be required for this)
  ];
  

  const quillModules = {
    toolbar: toolbarOptions
  };

  const handleSaveAndReturnButton = async () => {

    try {
      const updatedSummary = await summariesApi.update(id, { text: summaryContent });
      
      // Optionally, you can update the local state with the response from the API
      setSummaryContent(updatedSummary.text);

      // Navigate to the recording view tabs page
      navigate(-1);
    } catch (error) {
      console.error('Error updating summary:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  }


  const handleQuillChange = (content: string) => {
    setSummaryContent(content);
  };


  useEffect(() => {
    async function fetchSummary() {
      const fetchedSummary = await summariesApi.getOne(id || "");
      setSummaryType(fetchedSummary.type);
      setSummaryContent(fetchedSummary.text);

      switch (fetchedSummary.type) {
        case "SOAP":
          setSummaryTitle("SOAP");
          break;
        case "REFERRING_DENTIST":
          setSummaryTitle("referring dentist");
          break;
        case "PATIENT":
          setSummaryTitle("patient");
          break
        case "EHR":
          setSummaryTitle("EHR");
          break
        default:
          // code to be executed if expression doesn't match any cases
          setSummaryTitle("a");
      }
      
      setIsLoading(false);
    }
    fetchSummary();
  }, [id]);

  return (
    <>
    <div className="flex flex-col grow bg-blue-50 p-2 pt-4 sm:pt-8">
      <Card className="flex flex-col grow p-0 mb-52 shadow-md max-w-5xl mx-auto">
        <CardHeader className="p-4 sm:p-6">
          <CardTitle className="flex justify-between items-center h-min">
            {"Editing " + summaryTitle + " summary"}
          </CardTitle>
        </CardHeader>
        <CardContent className="flex grow px-4 sm:px-6 py-0">
          <ReactQuill
            className="quill flex flex-col grow rounded-md"
            ref={quillRef}
            readOnly={false}
            modules={quillModules}
            value={summaryContent}
            onChange={(content, delta, source, editor) => {
              if (source === 'user') {
                handleQuillChange(content);
              }
            }}
          />
        </CardContent>
        <CardFooter/>
      </Card>
    </div>
    <BottomBar>
      <Button
          className="border shadow-sm"
          variant="outline"
          onClick={() => navigate(-1)}
          disabled={isLoading}
        >
          Back
      </Button>
      
      
      <Button
          className="shadow-sm"
          type="submit"
          onClick={() => handleSaveAndReturnButton()}
          disabled={isLoading}
        >
          Save and Return
      </Button>
    </BottomBar>     
  </>
  );
};

export default SummaryEditPage;