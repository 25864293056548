import React, { useEffect, useState } from "react";
import { Plus, Trash2, AlertTriangle } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import { useOAuthCredentialStore } from "@/store/useOAuthCredentialStore";
import { emailAccountApi } from "../../services/api";
import GoogleAuthButton from "@/components/GoogleAuthButton";
import MicrosoftAuthButton from "@/components/MicrosoftAuthButton";

const OAuthCredentialsPage: React.FC = () => {
  const { emailAccounts, setEmailAccounts, deleteEmailAccount } =
    useOAuthCredentialStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchEmailAccounts = async () => {
      try {
        const fetchedEmailAccounts = await emailAccountApi.getAll();
        setEmailAccounts(fetchedEmailAccounts);
      } catch (error) {
        console.error("Failed to fetch email accounts:", error);
      }
    };
    fetchEmailAccounts();
  }, [setEmailAccounts]);

  const handleDelete = async (id: number) => {
    try {
      await emailAccountApi.delete(id);
      deleteEmailAccount(id);
    } catch (error) {
      console.error("Failed to delete email account:", error);
    }
  };

  const handleAddAccountSuccess = (data: any) => {
    console.log("Account added successfully:", data);
    setIsDialogOpen(false);
    // Refresh email accounts list
    emailAccountApi.getAll().then(setEmailAccounts);
  };

  const handleAddAccountFailure = (error: any) => {
    console.error("Failed to add account:", error);
    // Handle error (e.g., show error message to user)
  };

  return (
    <div className="p-12 flex-1 border-y">
      <div>
        <div className="text-xl font-semibold leading-none tracking-tight mb-1">
          Email Accounts
        </div>
        <div className="text-sm text-muted-foreground">
          Manage your connected email accounts
        </div>
      </div>
      <Card className="mt-8 flex-1">
        <CardHeader>
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Connected Accounts</h3>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="mr-2 h-4 w-4" />
                  Add Account
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Add Account</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4">
                  <div className="grid gap-2 p-5 bg-gray-50 border border-gray-100 rounded-lg">
                    <h4 className="font-medium leading-none">Gmail Account</h4>
                    <p className="text-sm text-muted-foreground mb-2">
                      Connect your Gmail account to organize your emails
                    </p>
                    <GoogleAuthButton
                      onSuccess={handleAddAccountSuccess}
                      onFailure={handleAddAccountFailure}
                      forAddEmailAccount
                    />
                  </div>
                  <div className="grid gap-2 mt-2 p-5 bg-gray-50 border border-gray-100 rounded-lg">
                    <h4 className="font-medium leading-none">
                      Microsoft 365 Account
                    </h4>
                    <p className="text-sm text-muted-foreground mb-2">
                      Connect your Microsoft 365 account to organize your emails
                    </p>
                    <MicrosoftAuthButton
                      onSuccess={handleAddAccountSuccess}
                      onFailure={handleAddAccountFailure}
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent>
          {emailAccounts.length === 0 ? (
            <Alert className="bg-slate-50">
              <AlertTriangle className="h-4 w-4" />
              <AlertTitle>No accounts connected</AlertTitle>
              <AlertDescription>
                Add an email account to get started with organizing your emails.
              </AlertDescription>
            </Alert>
          ) : (
            <div className="space-y-4">
              {emailAccounts.map((account) => (
                <div
                  key={account.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200"
                >
                  <div>
                    <p className="font-medium">{account.email}</p>
                    <p className="text-sm text-gray-500">
                      {account.oauth_credential_provider}
                      {account.is_primary && " (Primary)"}
                    </p>
                  </div>
                  {!account.is_primary && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleDelete(account.id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default OAuthCredentialsPage;
