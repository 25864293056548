import React, { ReactNode } from "react";

interface BottomBarProps {
  children: ReactNode;
}

const BottomBar: React.FC<BottomBarProps> = ({ children }) => {
  return (
    <div className="flex justify-center w-full p-4 sm:p-8 fixed bottom-0 z-10">
      <div className="flex flex-col justify-between w-full max-w-2xl h-min p-2 border bg-white shadow-xl rounded-lg space-y-2">
        {children}
      </div>
    </div>
  );
};

export default BottomBar;
