import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertTriangle } from "lucide-react";
import { useEmailTypeStore } from "@/store/useEmailTypeStore";
import { useOAuthCredentialStore } from "@/store/useOAuthCredentialStore";
import { emailTypeApi, emailAccountApi } from "@/services/api";
import { generateColorPairings } from "@/lib/colorContrastUtils";
import logo from "@/assets/images/Logo.svg";

interface EmailOnboardingStartProps {
  onNext: () => void;
}

const EmailOnboardingStart: React.FC<EmailOnboardingStartProps> = ({
  onNext,
}) => {
  const { emailAccounts, setEmailAccounts } = useOAuthCredentialStore();
  const { emailTypes, setEmailTypes } = useEmailTypeStore();
  const colorPairings = generateColorPairings();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedEmailTypes, fetchedEmailAccounts] = await Promise.all([
          emailTypeApi.getAll(),
          emailAccountApi.getAll(),
        ]);
        setEmailTypes(fetchedEmailTypes);
        setEmailAccounts(fetchedEmailAccounts);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [setEmailTypes, setEmailAccounts]);

  const primaryAccount = emailAccounts.find((account) => account.is_primary);

  const handleGetStarted = () => {
    onNext();
  };

  const getTextColor = (backgroundColor: string) => {
    const pairing = colorPairings.find((p) => p.background === backgroundColor);
    return pairing ? pairing.text : "#000000";
  };

  return (
    <div className="flex-1 bg-slate-50 p-16 border border-slate-200 rounded-lg">
      <div className="max-w-4xl mx-auto">
        <div className="flex gap-8">
          <div className="bg-white rounded-lg shadow p-6 mb-8 flex-1">
            <h2 className="text-xl font-semibold mb-4">Connected Accounts</h2>
            {emailAccounts.length === 0 ? (
              <Alert className="bg-slate-50">
                <AlertTriangle className="h-4 w-4" />
                <AlertTitle>No accounts connected</AlertTitle>
                <AlertDescription>
                  Add an email account to get started with organizing your
                  emails.
                </AlertDescription>
              </Alert>
            ) : (
              <div className="space-y-2">
                {emailAccounts.map((account) => (
                  <div
                    key={account.id}
                    className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
                  >
                    <div>
                      <p className="font-medium">{account.email}</p>
                      <p className="text-sm text-gray-500">
                        {account.oauth_credential_provider}
                        {account.is_primary && " (Primary)"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="bg-white rounded-lg shadow p-6 flex-1">
            <h2 className="text-xl font-semibold mb-4">Categories to label</h2>
            {emailTypes.length === 0 ? (
              <Alert className="bg-slate-50">
                <AlertTriangle className="h-4 w-4" />
                <AlertTitle>No categories found</AlertTitle>
                <AlertDescription>
                  Please create categories before proceeding with the onboarding
                  process.
                </AlertDescription>
              </Alert>
            ) : (
              <div className="space-y-2">
                {emailTypes.map((type) => (
                  <div key={type.id} className="flex items-center">
                    <span
                      className="px-2 py-1 text-sm font-semibold rounded-lg"
                      style={{
                        backgroundColor: type.color,
                        color: getTextColor(type.color),
                      }}
                    >
                      {type.title}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <Button
          className="mt-8 w-full"
          size="lg"
          onClick={handleGetStarted}
          disabled={emailTypes.length === 0 || emailAccounts.length === 0}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default EmailOnboardingStart;
