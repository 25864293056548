import React, { useState, ChangeEvent, FormEvent } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../services/customApiInstance";

interface RouteParams {
  uidb64: string;
  token: string;
  [key: string]: string | undefined;
}

function ResetPasswordPage() {
  const { uidb64, token } = useParams<RouteParams>();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(e.target.value);

  const clearErrorMessage = () => setErrorMessage("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrorMessage();

    if (newPassword.length < 8) {
      setErrorMessage("Password must be at least 8 characters.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await api.put<{ message: string }>(
        `/users/reset-password/${uidb64}/${token}/`,
        {
          new_password: newPassword,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
      } else {
        setErrorMessage("Couldn't reset the password. Please try again.");
      }
    } catch (error: any) {
      setErrorMessage(
        error.response?.data?.message ||
          "Couldn't reset the password. Please try again."
      );
    }
  };

  return (
    <div className="main-content authentication-container">
      <h2 className="page-title">Reset Password</h2>
      {errorMessage && <div className="message-error mb-4">{errorMessage}</div>}
      {success ? (
        <div className="message-success mb-4">
          Password Reset Successfully. Please{" "}
          <Link to="/login" className="link">
            log in
          </Link>
          .
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="form-label" htmlFor="newPassword">
              New Password:
            </label>
            <input
              className="form-input"
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handleNewPasswordChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="form-label" htmlFor="confirmPassword">
              Confirm Password:
            </label>
            <input
              className="form-input"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
          </div>
          <div className="mb-4">
            <button className="form-btn-submit" type="submit">
              Reset Password
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ResetPasswordPage;
