import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { EmailType } from "../services/api";

interface EmailTypeStore {
  emailTypes: EmailType[];
  setEmailTypes: (emailTypes: EmailType[]) => void;
  addEmailType: (emailType: EmailType) => void;
  updateEmailType: (id: number, updatedEmailType: Partial<EmailType>) => void;
  deleteEmailType: (id: number) => void;
}

export const useEmailTypeStore = create<EmailTypeStore>()(
  immer((set) => ({
    emailTypes: [],
    setEmailTypes: (emailTypes) =>
      set((state) => {
        state.emailTypes = emailTypes;
      }),
    addEmailType: (emailType) =>
      set((state) => {
        state.emailTypes.push(emailType);
      }),
    updateEmailType: (id, updatedEmailType) =>
      set((state) => {
        const index = state.emailTypes.findIndex((et) => et.id === id);
        if (index !== -1) {
          Object.assign(state.emailTypes[index], updatedEmailType);
        }
      }),
    deleteEmailType: (id) =>
      set((state) => {
        state.emailTypes = state.emailTypes.filter((et) => et.id !== id);
      }),
  }))
);
