import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import {
  generateColorPairings,
  backgroundColors,
  colorPairings,
} from "../lib/colorContrastUtils";

interface ColorPairing {
  background: string;
  text: string;
}

interface ColorSwatchPickerProps {
  color: string;
  onChange: (color: string) => void;
}

const ColorSwatchPicker: React.FC<ColorSwatchPickerProps> = ({
  color,
  onChange,
}) => {
  const [selectedColor, setSelectedColor] = useState<ColorPairing | null>(null);

  useEffect(() => {
    const pairings = generateColorPairings();
    const initialPairing = pairings.find(
      (pairing) => pairing.background === color
    );
    if (initialPairing) {
      setSelectedColor(initialPairing);
    }
  }, [color]);

  const handleColorChange = (e: React.MouseEvent, background: string) => {
    e.preventDefault();
    const text = colorPairings[background];
    setSelectedColor({ background, text });
    onChange(background);
  };

  const renderColorSwatch = (bgColor: string) => (
    <button
      key={bgColor}
      className="w-6 h-6 flex items-center justify-center"
      style={{ backgroundColor: bgColor }}
      onClick={(e) => handleColorChange(e, bgColor)}
    >
      {selectedColor?.background === bgColor && (
        <CheckIcon
          className="w-4 h-4"
          style={{ color: colorPairings[bgColor] }}
        />
      )}
    </button>
  );

  return (
    <div className="space-y-4 pl-2 py-2">
      <div>
        <div className="flex flex-col space-y-1">
          {/* Grays */}
          <div className="flex">
            {backgroundColors[8].map(renderColorSwatch)}
          </div>
          {/* Reds, Oranges, Yellows */}
          <div className="flex">
            {[
              ...backgroundColors[0],
              ...backgroundColors[1],
              ...backgroundColors[2],
            ].map(renderColorSwatch)}
          </div>
          {/* Greens, Cyans, Blues, Purples, Magentas */}
          <div className="flex">
            {[
              ...backgroundColors[3],
              ...backgroundColors[4],
              ...backgroundColors[5],
            ].map(renderColorSwatch)}
          </div>
          <div className="flex">
            {[...backgroundColors[6], ...backgroundColors[7]].map(
              renderColorSwatch
            )}
          </div>
        </div>
      </div>
      {selectedColor && (
        <div className="mt-4">
          <h3 className="text-sm font-medium mb-2 text-gray-700">Preview</h3>
          <div
            className="py-1 px-2 inline-block rounded text-sm"
            style={{
              backgroundColor: selectedColor.background,
              color: selectedColor.text,
            }}
          >
            <span>Sample Text</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorSwatchPicker;
