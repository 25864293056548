function PrivacyPolicy(): JSX.Element {
  return (
    <div className="privacy-policy larger-content">
      <h1 className="text-3xl font-semibold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        We are committed to protecting the privacy of our users, especially the
        women entrepreneurs who are part of our community. This Privacy Policy
        outlines how we collect, use, share, and protect your personal
        information when you access and use our platform.
      </p>

      <p className="mb-4">
        By accessing or using our platform, you consent to the practices
        described in this Privacy Policy. If you do not agree with this Privacy
        Policy, please do not use our platform.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        1. Information We Collect
      </h2>
      <p className="mb-4">
        We may collect various types of information when you use our platform,
        including:
      </p>

      <ul className="list-disc list-inside pl-4 mb-4">
        <li>
          Personal Information: Information that can identify you, such as your
          name, email address, phone number, and other contact details.
        </li>
        <li>
          Profile Information: Information you provide when creating or updating
          your profile, including your photograph, skills, interests, and
          professional background.
        </li>
        <li>
          Communication Data: Information you provide through messages, posts,
          comments, or any other content you share on our platform.
        </li>
        <li>
          Usage Data: Information about how you interact with our platform, such
          as your browsing history, IP address, device information, and
          referring website.
        </li>
        <li>
          Cookies and Tracking Technologies: We use cookies and similar
          technologies to collect information about your interactions with our
          platform. You can manage your cookie preferences by adjusting your
          browser settings.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        2. How We Use Your Information
      </h2>
      <p className="mb-4">
        We may use your information for various purposes, including but not
        limited to:
      </p>

      <ul className="list-disc list-inside pl-4 mb-4">
        <li>Providing and improving our platform's services.</li>
        <li>
          Communicating with you, responding to your inquiries, and providing
          support.
        </li>
        <li>Personalizing your experience on our platform.</li>
        <li>
          Analyzing and monitoring usage patterns to enhance our services and
          security.
        </li>
        <li>Complying with legal obligations and resolving disputes.</li>
        <li>
          Sending you updates, newsletters, and promotional content (you can
          opt-out at any time).
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        3. Sharing Your Information
      </h2>
      <p className="mb-4">We may share your information with:</p>

      <ul className="list-disc list-inside pl-4 mb-4">
        <li>
          Service providers who assist us in operating, maintaining, and
          improving our platform.
        </li>
        <li>
          Legal authorities when required by law or to protect our rights,
          users, or others.
        </li>
        <li>
          Third parties with your consent or as otherwise disclosed in this
          Privacy Policy.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">4. Security</h2>
      <p className="mb-4">
        We take reasonable measures to protect your information from
        unauthorized access, disclosure, alteration, or destruction. However, no
        data transmission over the internet is completely secure, and we cannot
        guarantee the security of your information.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">5. Your Rights</h2>
      <p className="mb-4">
        You have certain rights regarding your personal information, including
        the right to access, correct, delete, or restrict the processing of your
        data. Please contact us at{" "}
        <a
          href="mailto:team@womenfoundersbay.com"
          className="text-blue-500 hover:underline"
        >
          team@womenfoundersbay.com
        </a>{" "}
        to exercise these rights.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        6. Changes to this Privacy Policy
      </h2>
      <p className="mb-4">
        We may update this Privacy Policy to reflect changes in our practices or
        legal requirements. Please check this page regularly for updates. If we
        make significant changes, we will notify you through the platform or
        other means.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">7. Contact Us</h2>
      <p>
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or your personal information, please contact us at{" "}
        <a
          href="mailto:team@womenfoundersbay.com"
          className="text-blue-500 hover:underline"
        >
          team@womenfoundersbay.com
        </a>
        .
      </p>
    </div>
  );
}

export default PrivacyPolicy;
