import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../services/customApiInstance";

interface Params {
  uid: string;
  token: string;
  [key: string]: string | undefined;
}

const EmailVerificationPage: React.FC = () => {
  const { uid, token } = useParams<Params>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await api.get(`/users/verify-email/${uid}/${token}/`, {
          authRequired: false,
        });
        setMessage(response.data.message);
        setIsSuccess(true);
        setTimeout(() => navigate("/login"), 3000);
      } catch (error) {
        setMessage(
          "Failed to verify email. The link may be expired or invalid."
        );
        setIsSuccess(false);
        setTimeout(() => navigate("/login"), 5000);
      } finally {
        setLoading(false);
      }
    };
    verifyEmail();
  }, [uid, token, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-cyan-500 to-cyan-600">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
        <h1 className="text-3xl font-bold text-cyan-800 mb-6 text-center">
          Email Verification
        </h1>
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cyan-700"></div>
          </div>
        ) : (
          <div
            className={`text-center p-4 rounded-md ${
              isSuccess
                ? "bg-cyan-100 text-cyan-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            <p className="text-lg">{message}</p>
            {isSuccess && (
              <svg
                className="mx-auto mt-4 h-12 w-12 text-cyan-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
            {!isSuccess && (
              <svg
                className="mx-auto mt-4 h-12 w-12 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </div>
        )}
        <p className="mt-6 text-center text-cyan-600">
          Redirecting to login page{" "}
          {isSuccess ? "in 3 seconds..." : "in 5 seconds..."}
        </p>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
